import React from 'react'
import video from "../../images/Video/Intro-Short-Video-Edinboro-University.mp4";

function Experience({ experienceData = null }) {

  return (
    <>
      <section className="experience">
        <div className="bac-img">
          {
            typeof (experienceData?.video) !== "undefined" && experienceData?.video !== "" ?
              <video
                key={experienceData?.video ? experienceData?.video : video}
                autoPlay="true" loop id="video-background">
                <source src={experienceData?.video ? experienceData?.video : video} type="video/mp4" />
              </video> : null

          }

          {/* <img src={experienceData?.video} className="img-fluid" /> */}
          <div className="play-button">
            <a href="#">
              <i className="fa-regular fa-circle-play" />
            </a>
          </div>
        </div>
        <div className="container">
          <div className="content">
            <h2>
              Studying at <br />
              VIP SUCCESS
            </h2>
            <p>
              {experienceData?.information}
            </p>
            {/* <p>
              Whether you are looking to start a career or want to progress your
              career in management or Entrepreneurship we offer you the best
              opportunity to get trained by Global industry faculty who are
              experienced & Successful experts in their field.
            </p>
            <p>
              We pride ourselves on establishing growth pathways which helps you
              to integrate prior experience whilst empowering you grow.
            </p>
            <p>
              GUA helps each student/participant to think entrepreneurially,
              whilst also ensuring that we engage and inspire you through
              advance teaching coaching techniques, We are pioneering a new take
              on higher education and leadership development.
            </p> */}

          </div>
        </div>
      </section>
    </>
  )
}

export default Experience