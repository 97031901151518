import React from "react";

function StudentInfo({ experienceData = null }) {
  return (
    <>
      <section className="about-student">
        <div className="container">
          <div className="row" style={{ gap: 20, justifyContent: 'center' }}>
            {experienceData?.details?.map((item, index) => {
              return (
                <div key={index}>
                  <div className="wrapper">
                    <h2>{item?.count}</h2>
                    <p>{item?.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default StudentInfo;
