import React from "react";
import parse from "html-react-parser";

function MiddleSection({ excellience = null }) {
  return (
    <>
      <section className="degree_LevelSection">
        <div className="container" >
          <h2 className="degree_LvlTEXt">{excellience?.heading}</h2>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <p
                className="degree_Para"
                style={{ fontWeight: "500", fontSize: "22px" }}
              >
                {parse(`${excellience?.longDesc}`)}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MiddleSection;
