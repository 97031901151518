import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./component/Layout/index"
import Home from "./View/Home";
import About from "../src/View/About/Index";
import Centreofexcellence from "../src/View/Centreofexcellence/Index";
import Tution from "../src/View/TutionFees/Index";
import Contact from "../src/View/Contact/Index";
import Admission from "../src/View/Admission/Index";
import Faculty from "../src/View/Faculty/Index";
import News from "../src/View/News/Index";
import Goal from "../src/View/OurGoal/Index"
import Gallery from "../src/View/Gallery/Index";
import History from "../src/View/History/index";
import Event from "../src/View/Events/Index";
import Donation from "../src/View/Donation/Index";
import Champion from './View/Champion';
import Undergraduate from './View/Admission/Undergraduate';
import Graduate from './View/Admission/Graduate';
import Certificate from './View/Admission/Certificate';
import DistanceEducation from './View/Admission/DistanceEducation';
import HonersAward from './View/Admission/HonersAward';
import Undergraduateadmissions from './View/Admission/Undergraduateadmissions';
import GraduateAdmission from './View/Admission/GraduateAdmissions';
import Scholarship from './View/Scholarship/Index';
import CourseFinder from './View/Admission/CourseFinder';
import Applyonline from './View/Admission/Applyonline';
import Applicationform from './component/Simpleform/Applicationform';
import Blog from "../src/View/Blog/Index";
import PartnerShip from "./View/Partnership";
import AdmissionTemp from './View/Admission/AdmissionTemp';
function App() {
  return (
    <BrowserRouter>

      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/centre-of-excellence' element={<Centreofexcellence />} />
          {/* <Route path='/tution' element={<Tution/>} /> */}
          <Route path='/contact' element={<Contact />} />
          <Route path='/admission' element={<Admission />} />
          <Route path='/undergraduate' element={<Undergraduate />} />
          <Route path='/graduate' element={<Graduate />} />
          <Route path='/certificate' element={<Certificate />} />
          <Route path='/distanceeducation' element={<DistanceEducation />} />
          <Route path='/honersaward' element={<HonersAward />} />
          <Route path='/undergraduateadmissions' element={<Undergraduateadmissions />} />
          <Route path='/graduateadmissions' element={<GraduateAdmission />} />
          <Route path='/scholarship' element={<Scholarship />} />
          <Route path='/coursefinder' element={<CourseFinder />} />
          <Route path='/applyonline' element={<Applyonline />} />
          <Route path='/faculty' element={<Faculty />} />
          <Route path='/news' element={<News />} />
          <Route path='/our-goal' element={<Goal />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path="/history" element={<History />} />
          <Route path="/events" element={<Event />} />
          <Route path="/donation" element={<Donation />} />
          <Route path="/champion" element={<Champion />} />
          <Route path="/apply" element={<Applicationform />} />
          <Route path="/apply-scholarship" element={<Applicationform isScholarship={true} />} />
          <Route path="/admission/:page" element={<AdmissionTemp />} />
          <Route path="/scholarship" element={<Scholarship />} />
          <Route path="/partnerShip" element={<PartnerShip />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
