import React, { useState, useEffect } from "react";
import Form from "../../component/Form/Form";
import about1 from "../../images/brydon-mccluskey-GvPceVqbxm4-unsplash.jpg";
// import about2 from "../../images/alexis-brown-Xv7k95vOFA-unsplash.jpg";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import { requestData } from "../../Utils/HttpClient";
import Mission from "../About/Mission";
import Intro from "../../component/Home/Intro/Intro";
import News from "../Home/News";

export default function Index() {

  const [newsData, setNewsData] = useState(null)
  const [newsAllData, setNewsAllData] = useState([])
  const [missionData, setMissionData] = useState(null)

  useEffect(() => {
    fetchNewsData()
  }, [])


  const fetchNewsData = async () => {
    const result = await requestData("user/viewNews", "GET");
    // console.log(result);
    if (result && result.status) {
      setNewsData(result.data[0]);
    }
    fetchMissionData()
    fetchAllNewsData();
  };

  const fetchMissionData = async () => {
    const result = await requestData("user/viewMissionAndValues", "GET");
    // console.log(result);
    if (result && result.status) {
      setMissionData(result.data[0]);
    }
  };

  const fetchAllNewsData = async () => {
    const result = await requestData("user/viewNewsAndEvent", "GET");
    // console.log(result);
    if (result && result.status) {
      setNewsAllData(result.data);
    }
  };

  return (
    <>
      {/* <section
        className="about-missoin"
        style={{ backgroundImage: `url('${about1}')` }}
      > */}
      {/* <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="cnt">
                  <h1>{newsData?.pageName}</h1>
                  <p>
                    {newsData?.shortDesc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* </section> */}
      <Intro pageName={newsData?.pageName} description={newsData?.shortDesc} bgImage={newsData?.bgImage} />
      <section className="about-para">
        <div className="cust-container">
          <div className="cnt text">
            {/* <p>
              <br/>
              <br/>
            </p> */}
            <p>
              {newsData?.longDesc}
            </p>
            {/* <p>
              Since our founding, The Georgetown Wesleyn University of Americas
              has redrawn and redefined the boundaries of intellectual and
              creative thought Leadership . Our rigorous, multidimensional
              approach to education dissolves walls between disciplines and
              helps nurture progressive minds. At our university, students have
              the freedom to shape their unique, individual paths for a complex
              and rapidly changing world.
            </p> */}
            <p></p>
          </div>
        </div>
      </section>

      {/* News Section  */}
      <News newsData={newsAllData} isNewspage={true} />

      <Mission missionData={missionData} />

      <Form />
      <GoToTop />
    </>
  );
}
