import React from 'react'
import a5 from "../../images/rectangle-yellow.png";
import a2 from "../../images/apply.jpg";

function PresidentGoal({ presidentGoal = null }) {
  return (
    <>
        <section className="applyfor_sec_area ourGoal-applyarea">
        <div className="container-fluid">
          <div className="gapps">
            <div className="row">
              <div className="col-xl-7 col-lg-6 col-md-12 col-12 m-auto">
                <div className="left_cnt">
                  <div className="head">
                    <h2>{presidentGoal?.name}</h2>
                  </div>
                  <div>
                    <h5>{presidentGoal?.designation}</h5>
                  </div>
                  <div className="text">
                    <p>
                      {presidentGoal?.description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                <div
                  className="right_cnt"
                  style={{
                    backgroundImage: `url(${a5})`,
                    backgroundPosition: "bottom right",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "auto",
                  }}
                ></div>
                <div className="image_area">
                  <img src={presidentGoal?.image} className="img-fluid" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PresidentGoal