import React from "react";
import parse from "html-react-parser";
const LongDesc = ({ aboutData = null }) => {
  // console.log("Long", aboutData);
  return (
    <>
      <section className="about-para">
        <div className="cust-container">
          <div className="cnt text" style={{paddingTop: 0}}>
            {parse(`${aboutData?.longDesc}`)}
          </div>
        </div>
      </section>
    </>
  );
};

export default LongDesc;
