import React, {useState, useEffect} from "react";
import Form from "../../component/Form/Form";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import { requestData } from "../../Utils/HttpClient";
import PresidentGoal from "./PresidentGoal";
import Directory from "../Faculty/Directory";

export default function Index() {

    const [goalIntro, setGoalIntro] = useState(null)
    const [presidentGoal, setPresidentGoal] = useState(null)
    const [directoryData, setDirectoryData] = useState(null)

    useEffect(() => {
        fetchGoalIntroData()
    }, [])
    

    const fetchGoalIntroData = async () => {
        const result = await requestData("user/viewGoalIntro", "GET");
        // console.log(result);
        if (result && result.status) {
            setGoalIntro(result.data[0]);
        }
        fetchPresidentGoalData()
    };

    const fetchPresidentGoalData = async () => {
        const result = await requestData("user/viewPresidentGoal", "GET");
        // console.log(result);
        if (result && result.status) {
            setPresidentGoal(result.data[0]);
        }
        fetchDirectoryData()
    };

    const fetchDirectoryData = async () => {
        const result = await requestData("user/viewFacultyList", "GET");
        // console.log("Bannerdata", result);
        if (result && result.status) {
          setDirectoryData(result.data[0]);
            // console.log("AboutHis", result.data[0]);
        }
      };

  return (
    <>
      <section
        className="about-missoin"
        style={{ backgroundImage: `url(${goalIntro?.bgImage})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="cnt">
                  <h1>{goalIntro?.pageName}</h1>
                  <p>
                    {goalIntro?.shortDesc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PresidentGoal presidentGoal={presidentGoal} />

      <Directory directoryData={directoryData} />

      <Form />
      <GoToTop />
    </>
  );
}
