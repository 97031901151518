import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Intro from "../../../../component/Home/Intro/Intro";
import { requestData } from "../../../../Utils/HttpClient";
import RegDetails from "./RegDetails";
import ScholarshipTable from "./ScholarshipTable";

const Index = () => {
  const [regData, setRegData] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await requestData(`user/viewRegistrationInfo`, "GET", {});

    if (result && result.status) {
      // console.log(result, `result`);
      setRegData(result.data[0]); // set data
    } else {
      toast.error(result.message || result.error);
    }
  };
  // console.log("regData", regData);
  return (
    <>
      <RegDetails
        title={regData?.title}
        heading={regData?.heading}
        option={regData?.option}
      />
      <ScholarshipTable data={regData?.delivery} />
    </>
  );
};

export default Index;
