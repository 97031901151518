import React from "react";

function AboutImage({ experienceData = null }) {
  return (
    <>
      <section className="about-gallery">
        <div className="container-fluid">
          <div className="row">
            {experienceData?.details?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-xl-4 col-lg-4 col-md-4 col-xs-4 col-sm-4 col-12 mb-3"
                >
                  <div className="wrapper">
                    <div>
                      <img src={item?.image} className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutImage;
