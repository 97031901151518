import React, { useEffect, useState } from "react";
// import f1 from "../../images/faculty-banner.jpg";
// import f2 from "../../images/calvin.jpg";
// import f3 from "../../images/cordelia.jpg";
// import f4 from "../../images/eleanor.jpg";
// import f5 from "../../images/eva.jpg";
// import f6 from "../../images/lecturer-philosophy.jpg";
// import f7 from "../../images/luke.jpg";
// import f8 from "../../images/professor-history.jpg";
// import f9 from "../../images/professor-philosophy.jpg";
import Form from "../../component/Form/Form";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import { requestData } from "../../Utils/HttpClient";
import Directory from "../Faculty/Directory";
function Undergraduate() {
  const [facultyData, setFacultyData] = useState(null);
  const [directoryData, setDirectoryData] = useState(null);

  useEffect(() => {
    fetchAboutFacultyData();
  }, []);

  const fetchAboutFacultyData = async () => {
    const result = await requestData("user/viewAboutIntro", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setFacultyData(result.data[0]);
      // console.log("AboutHis", result.data[0]);
    }
    fetchDirectoryData();
  };

  const fetchDirectoryData = async () => {
    const result = await requestData("user/viewFacultyList", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setDirectoryData(result.data[0]);
      // console.log("AboutHis", result.data[0]);
    }
  };
  return (
    <>
      <section
        className="faculty-missoin"
        style={{ backgroundImage: `url(${facultyData?.bgImage})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="cnt">
                  <h1>{facultyData?.pageName}</h1>
                  <p>{facultyData?.shortDesc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faculty_Section">
        <div className="container">
          <p className="para">{facultyData?.longDesc}</p>
          {/* <p className="para">
          Everyone who joins GWUA has the opportunity to network with global
          faculty & industry experts including leadership teams from corporate
          businesses globally. Our networking events are the most engaged
          forums of its kind and is free to all members who are registered
          with GWUA.
        </p> */}
        </div>
      </section>

      <Directory directoryData={directoryData} />

      <Form />
      <GoToTop />
    </>
  );
}

export default Undergraduate;
