import React, { Fragment, useEffect, useState } from "react";
import video from "../../images/Video/Intro-Short-Video-Edinboro-University.mp4";
import { requestData } from "../../Utils/HttpClient";
const Banner = () => {
  const [bannerData, setBannerData] = useState(null);
  useEffect(() => {
    fetchBannerData();
  }, []);

  const fetchBannerData = async () => {
    const result = await requestData("user/viewBanner", "GET");
    console.log("Bannerdata", result);
    if (result && result.status) {
      setBannerData(result.data[0]);
    }
  };
  return (
    <Fragment>
      <div className="banner">
        <video
          key={bannerData?.bannerVideo ? bannerData?.bannerVideo : video}
          autoPlay
          loop
          muted
          id="video-background"
        >
          <source
            src={bannerData?.bannerVideo ? bannerData?.bannerVideo : video}
            type="video/mp4"
          />
        </video>
        <div className="elementor-background-overlay" />
        <div className="container elementor-background-overlay-text">
          <div
            className="widget-container"
            style={{ textAlign: "center", marginTop: "-13%" }}
          >
            <h4 className="normal-text" style={{ width: 300 }}>
              {bannerData?.heading ? bannerData?.heading : "Global thinkers. Investing in Your Success."}
            </h4>
          </div>
        </div>
      </div>

      {/*--------Mobile view-------*/}
      <section
        id="static-banner"
        style={{ backgroundImage: `url(${bannerData?.bannerImage})` }}
      >
        <div className="container" style={{ maxWidth: 1260 }}>
          <div className="row">
            <h1 className="entry-mobile-title">{bannerData?.heading}</h1>
            {/* <h3 className="entry-mobile-para">
              Global thinkers. Engaged leaders. Investing in Your Success.
            </h3> */}
            {/* <a href="#" className="btn btn-programme">
              View Our Programmes <i className="fa-solid fa-arrow-right" />
            </a> */}
          </div>
        </div>
      </section>
    </Fragment >
  );
};

export default Banner;
