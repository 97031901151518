import React, { useState } from "react";
import Input from "../../component/UI/Input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { requestData } from "../../Utils/HttpClient";

const MessageForm = () => {
  const messageData = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  };

  const [messageInfo, setMessageInfo] = useState(messageData);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const handleValidation = () => {
   
      // console.log("messageInfo", messageInfo);
    let valid = Object.values(messageInfo).some((it) => it === "");
    // console.log("valid", valid, messageInfo);
    if (valid) {
      toast.warning("All field are required!!!", toastOptions);
      return false;
    }
    const { email } = messageInfo;
    let emailRegex = /^([\-\.0-9a-zA-Z]+)@([\-\.0-9a-zA-Z]+)\.([a-zA-Z]){1,7}$/;
    if (!emailRegex.test(email)) {
      toast.warning("Enter a valid email!", toastOptions);
      return false;
    } else {
      return true;
    }
  };

  const submitData = async (e) => {
    e.preventDefault();
    // console.log(handleValidation());
    if (handleValidation()) {
      const result = await requestData(
        "user/addQueryMessage",
        "POST",
        messageInfo
      );
      // console.log(result);
      if (result && result.status) {
        toast.success(result.message, toastOptions);
        setMessageInfo(messageData);
      } else {
        toast.error("Something went Wrong!", toastOptions);
      }
    }
  };

  return (
    <>
      <div className="col-md-6">
        <ToastContainer />
        <div className="sendMSGFORm">
          <h3>Send us a message</h3>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p> */}
          <div className="row">
            <div className="col-md-6">
              <Input
                type="text"
                label="First Name"
                placeholder="First Name"
                onChange={(e) =>
                  setMessageInfo({ ...messageInfo, firstName: e.target.value })
                }
              />
            </div>

            <div className="col-md-6">
              <Input
                type="text"
                label="Last Name"
                placeholder="Last Name"
                onChange={(e) =>
                  setMessageInfo({ ...messageInfo, lastName: e.target.value })
                }
              />
            </div>

            <div className="col-md-12">
              <Input
                type="text"
                label="Email"
                placeholder="Email"
                onChange={(e) =>
                  setMessageInfo({ ...messageInfo, email: e.target.value })
                }
              />
            </div>

            <div className="col-md-12">
              <Input
                type="textarea"
                label="Message"
                placeholder="Message"
                onChange={(e) =>
                  setMessageInfo({ ...messageInfo, message: e.target.value })
                }
              />
            </div>

            <div className="col-12">
              <button
                onClick={(e) => submitData(e)}
                className="btn btnsenDMssg"
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageForm;
