import React, { useEffect, useState } from "react";
import { viewFundNames } from "./OptionDataFrom";

function Step1({ callMack }) {
  const [fundname, setFundname] = useState([]);

  useEffect(() => {
    getFundnames()
  }, []);

  const getFundnames = async () => {
    let res = await viewFundNames();
    setFundname(res);
  };


  return (
    <div className="Givingpage-funds">
      <div className="givingpage-wrap">
        <p>
          If you know the name of the fund you'd like to give to, type it in
          here:
        </p>
        <div className="custom-amounts-first">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="NAME OF FUND"
              onChange={(e) => callMack(e.target.value)}
            />
          </div>
          {/* <p style={{ textAlign: "center" }}>OR</p>
          <div className="amounts-BTN">
            <button>Give To VIP-SUCCESS  GREATEST NEED</button>
          </div> */}

{
            fundname.map((fund, key) => {
              return (
                <>
                  <p style={{ textAlign: "center" }}>OR</p>
                  <div className="amounts-BTN">
                    <button>{fund?.fundname}</button>
                  </div>
                </>
              )

            })
          }


          <p style={{ textAlign: "center" }}>OR</p>
          <div className="amounts-BTN">
            <button>Explore Giving Opportunities</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step1;
