import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { requestData } from "../../../Utils/HttpClient";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const MakeDonCeiGift = () => {
  const [overView, setOverView] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await requestData(`user/viewScholarshipOverview`, "GET", {});

    if (result && result.status) {
      setOverView(result.data[0]); // set data
    } else {
      toast.error(result.error || result.message);
    }
  };
  return (
    <section className="scholarship-donation">
      <div className="container">
        <div className="row">
          <div className="donation-heading">
            <h2>{overView?.title} </h2>
          </div>
          <div>
            <Link to="/donation" className="donation-btn">
              Donate
            </Link>
          </div>
        </div>
        <div className="row">
          {/* <div className="scholarship-donation-text">
            {parse(`${overView?.donationDesc}`)}
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default MakeDonCeiGift;
