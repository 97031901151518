import React from 'react';

const Input = ({ type, label, placeholder, onChange }) => {
    let input = null;
    switch (type) {
        case 'text':
            input = <div className="form-group">
                <label htmlFor="">{label}</label>
                <input
                    type={type}
                    className="form-control"
                    placeholder={placeholder}
                    onChange={onChange}
                />
            </div>
            break;
        case 'textarea':
            input = <div className="form-group">
                <label htmlFor="">{label}</label>
                <textarea
                    className="form-control"
                    rows={5}
                    placeholder={placeholder}
                    onChange={onChange}
                />
            </div>
            break;
        case 'file':
            input = <div className="input-group">
                <div className="custom-file">
                    <input
                        type={type}
                        className="custom-file-input form-control"
                        onChange={onChange}
                    />
                    <label className="custom-file-label">{label}</label>
                </div>
            </div>
            break;
        default:
            input = <div className="form-group">
                <label htmlFor="">{label}</label>
                <input
                    type={type}
                    className="form-control"
                    placeholder={placeholder}
                    onChange={onChange}
                />
            </div>
    }
    return input;
}

export default Input