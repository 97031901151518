import React, { useState, useEffect } from "react";
// import f1 from "../../images/faculty-banner.jpg";
// import f2 from "../../images/calvin.jpg";
// import f3 from "../../images/cordelia.jpg";
// import f4 from "../../images/eleanor.jpg";
// import f5 from "../../images/eva.jpg";
// import f6 from "../../images/lecturer-philosophy.jpg";
// import f7 from "../../images/luke.jpg";
// import f8 from "../../images/professor-history.jpg";
// import f9 from "../../images/professor-philosophy.jpg";
// import Form from "../../component/Form/Form";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import { Link } from "react-router-dom";
// import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { requestData } from "../../Utils/HttpClient";
import Intro from "../../component/Home/Intro/Intro";
import Tution from "../Home/Tution";
import ScholarshipTable from "./Component/Registration/ScholarshipTable";
import RegistrationSec from "./Component/Registration/Index";
import OverViewAndOpp from "./Component/overviewAndOpp/OverViewAndOpp";
import Directory from "../Faculty/Directory";
import MakeDonCeiGift from "./Component/MakeDonCeiGift";
import parse from "html-react-parser";

function Scholarship() {
  const [scholarshipIntro, setscholarshipIntro] = useState(null);
  const [feeData, setFeeData] = useState([]);
  useEffect(() => {
    fetchFeeData();
    fetchScholarshipIntroData();
  }, []);

  const fetchScholarshipIntroData = async () => {
    const result = await requestData(
      "user/viewScholarshipAndGrandIntro",
      "GET"
    );
    if (result && result.status) {
      setscholarshipIntro(result.data[0]);
    }
  };
  const fetchFeeData = async () => {
    const result = await requestData("user/viewTutionFees", "GET");
    // console.log(result);
    if (result && result.status) {
      setFeeData(result.data);
    }
  };
  // console.log('firsdfdsst', feeData)
  return (
    <>
      <Intro
        bgImage={scholarshipIntro?.bgImage}
        pageName={scholarshipIntro?.pageName}
        description={scholarshipIntro?.shortDesc}
      />
      <section className="faculty_Section">
        <div className="container">
         
          <p className="para">
         
            {/* Our Faculty are motivated to serve others – our team are global
            academics and professionals. */}
          </p>
        </div>
      </section>

      <section className="scholarship-text">
        <div className="container">
          <div className="scholarship-content">
            <h2 className="scholarships-overview">{scholarshipIntro?.heading}</h2>
            <div className="text">
              {parse(`${scholarshipIntro?.overview}`)}
              {/* <p>Save time & money</p>
              <p>
                Up to a $3k scholarship for qualifying participants with
                eligible credits and relevant experiences could be Eligible
                transfer credits could cover up to 75% of your chosen course.
              </p>
              <p>
                Lock in the peace of mind that comes with fixed, affordable
                tuition. You’ll enjoy one flat rate from the moment you enrol
                until the day you graduate from your program. That’s your
                Tuition Guarantee.
              </p>
              <p>
                Competency-based degrees you can finish in under a year for less
                than $5k.
              </p>
              <p>
                No application fee, no SAT or GMAT test requirements, and we’ll
                even request your prior college transcripts for you, at no cost
                to you.
              </p>
              <p>Start your growth program when you’re ready.</p> */}
            </div>
          </div>
        </div>
      </section>
      <Tution feeData={feeData} />

      <RegistrationSec />

      <OverViewAndOpp />
      <Directory />
      <MakeDonCeiGift />
      {/* <Form /> */}
      <GoToTop />
    </>
  );
}

export default Scholarship;
