import React from "react";
// import a5 from "../../images/1_Nq1ZWm_LzLQB4rKQVXHe5w.jpeg";
import a6 from "../../images/shape-_1_.png";
import a7 from "../../images/rectangle-yellow.png";
import parse from "html-react-parser";
const Designation = ({ campusData = null }) => {
  return (
    <>
      <section className="emma_matthews_area">
        <div className="container-fluid" style={{ padding: "0 5%" }}>
          <h2 className="pre-message">{campusData?.title}</h2>
          {campusData?.message?.map((item, index) => {
            return (
              <div key={index} className="main-wrap row">
                <div className="col-lg-5 col-md-12 col-12">
                  <div
                    className="left_cnt"
                  // style={{ backgroundImage: `url('${item?.image || a6}')` }}
                  >
                    <div style={{ position: "relative" }} className="president">
                      <img src={item?.image} className="img-fluid" alt="img" />
                      <div
                        className="img_area"
                        style={{ backgroundImage: `url('${a7}')` }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12">
                  <div className="right_cnt">
                    <div className="head">
                      <h2>{item?.name}</h2>
                    </div>
                    <div className="line">
                      <h6>{item?.designation}</h6>
                    </div>
                    <div className="text" style={{ paddingTop: 0 }}>
                      <p>{parse(`${item?.note}`)}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Designation;
