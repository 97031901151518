import React, { Fragment } from "react";
import parse from "html-react-parser";
export const Quickview = ({ quickViewData = null }) => {
  // console.log("quickViewData", quickViewData);

  return (
    <Fragment>
      <section id="program-academy">
        <div className="container" >
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12 col-12">
              <h1
                className="campus-titlequckj text-center"
            
              >
                {quickViewData?.heading}
              </h1>
            </div>
            {/* <div className="col-md-6 col-lg-6 col-xl-6 col-12">
              <h1 className="campus-title text-right mt-3">AM</h1>
            </div> */}
            {/* <div className="col-md-12 col-lg-12 col-xl-12 col-12">
              <h3 className="academics">&amp; Academics</h3>
            </div> */}
            <div className="col-md-12 col-lg-12 col-xl-12 col-12">
              <p
                // style={{
                //   margin: "40px auto",
                //   maxWidth: "90%",
                //   fontWeight: 400,
                //   fontSize: 18,
                //   paddingBottom: 30,
                // }}
              >
                {parse(`${quickViewData?.shortDesc}`)}
              </p>
            </div>
          </div>
          <div className="row">
            {quickViewData?.category?.map((item, index) => {
              //   console.log("Title", item?.title);
              return (
                <div
                  key={index}
                  className="col-md-3 col-lg-3 col-xl-3 col-12 view-responsive"
                  style={{ paddingLeft: 0, paddingRight: 0, height: "80vh" }}
                >
                  <div
                    className="our-programmes-item hide-scrollbar"
                    style={{
                      backgroundColor: `${item.color}`,
                      height: "100%",
                    }}
                  >
                    <h4 className="">{item?.title}</h4>
                    <p>{parse(`${item?.description}`)}</p>
                    {/* <a href="#">Major Programs</a>
                    <a href="#">Minors Programs</a> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Fragment>
  );
};
