import React from 'react'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
export default function Form() {
  return (
   <>
   <section className="future_Career" id="future_Career">
  <div className="form_SectionInner">
    <div className="container ">
      <div className="row">
        <div className="col-md-12">
          <div className="form_SEction">
            <h1 className="form_TExt">FORM</h1>
          </div>
          <div className="form_Inner">
            <h4 className="areYou">
            Lets accelerate your growth to take the next step toward your future career?
            </h4>
            <Link to="/apply" className="btn appliation_FOrm">
              Application Form <i className="fa-solid fa-arrow-right-long" />
            </Link>
            <div className="d-flex aling-items-center justify-content-start justify-content-sm-center mt-4">
              <a href="" className="reques_BTN">
                <span>Request Info</span>
              </a>
              <a href="" className="reques_BTN">
                <span>Visit</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
   </>
  )
}
