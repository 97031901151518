import React from 'react'
import imageStudent from "../../images/student_Image.jpg";

const Partnership = ({ partnershipData = null }) => {
  // console.log("Partnership", partnershipData);
  return (
    <>
      <section className="patnership_Section">
        <div className="container">
          <div className="row">
            <div className='col-md-7'>
              <h4 className="title">{partnershipData?.heading}</h4>
            </div>
            <div className="col-md-5">
              <div className="partnershipImage">
              </div>
            </div>

          </div>
          <div className="row mt-0 mt-md-4">
            <div className="col-md-12">
              <p className="para" style={{textAlign: 'justify' }}>
                {partnershipData?.description}
              </p>
            </div>
            {/* <div className="col-md-5">
                <img src={partnershipData?.image} alt="" className="img-fluid" />
            </div> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default Partnership