import React from "react";

const Intro = ({ bgImage, pageName, description }) => {
  return (
    <section
      className="faculty-missoin"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="cnt">
                <h1>{pageName}</h1>
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
