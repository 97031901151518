import React from "react";
import Slider from "react-slick";
// import history_Person_Two from "../../images/history_person_two.jpg";
// import history_Person from "../../images/history_person.jpg";

const UniversityDetails = ({ universityDetail = null }) => {
  // console.log("UniDtl", universityDetail);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="leading_University">
        <div className="container-fluid" style={{ maxWidth: "1260px" }}>
          <h3 className="leading">{universityDetail?.heading}</h3>
          {/* <div className="row"> */}
          <div className="sliderwrapper" style={{ height: "800px" }}>
            <Slider {...settings}>
              {universityDetail?.history?.map((item, index) => {
                return (
                  <div key={index} className="leadImage ">
                    <div className="leadImageInner mr-1">
                      <img src={item?.image} alt="" className="img-fluid" />
                    </div>
                    <div className="captionLEading">
                      <h2>{item?.year}</h2>
                      <p>{item?.desc}</p>
                    </div>
                  </div>
                );
              })}
              {/*
                            <div className="leadImage">
                                <div className="leadImageInner">
                                    <img src={history_Person_Two} alt="" className="img-fluid" />
                                </div>
                                <div className="captionLEading">
                                    <h2>1980</h2>
                                    <p>Opening of the largest university library in New York</p>
                                </div>
                            </div> */}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default UniversityDetails;
