import { requestData } from "../../Utils/HttpClient";

export const getState = async () => {
  let result = await requestData(`user/viewStatelist`, "GET", {});
  if (result && result.status) {
    return result.data;
  }
};

export const getCity = async () => {
  let result = await requestData(`user/viewCountrylist`, "GET", {});
  if (result && result.status) {
    return result.data;
  }
};
export const expirationMonth = async () => {
  let result = await requestData(`user/viewExpirationMonth`, "GET", {});
  if (result && result.status) {
    return result.data;
  }
};
export const expirationYear = async () => {
  let result = await requestData(`user/viewExpirationYear`, "GET", {});
  if (result && result.status) {
    return result.data;
  }
};
export const viewMemorialType = async () => {
  let result = await requestData(`user/viewMemorialType`, "GET", {});
  if (result && result.status) {
    return result.data;
  }
};
export const viewSalutation = async () => {
  let result = await requestData(`user/viewSalutation`, "GET", {});
  if (result && result.status) {
    return result.data;
  }
};
export const viewFundNames = async () => {
  let result = await requestData(`user/viewfundname`, "GET", {});
  if (result && result.status) {
    return result.data;
  }
};
