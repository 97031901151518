import React, { useEffect, useState } from "react";
import DescriptionCard from "../../component/Home/DescriptionCard";
import { requestData } from "../../Utils/HttpClient";

const Welcome = () => {
  
  const [welcomeData, setWelcomeData] = useState(null);
  useEffect(() => {
    fetchWelcomeData();
  }, []);

  const fetchWelcomeData = async () => {
    const result = await requestData("user/viewWelcomeSection", "GET");
    // console.log(result);
    if (result && result.status) {
      setWelcomeData(result.data[0]);
    }
  };
  return (
    <section id="about">
      <div className="container" >
        <div className="row">
          <div className="main-item">
            <div className="main-item-wrapper1">
              <img
                src={welcomeData?.logo}
                className="img-fluid"
                alt="Logo Icon"
              />
            </div>
            <div className="main-item-wrapper2">
              <h2 className="entry-title">
                {welcomeData?.heading}
                {/* Welcome to GWUA Leadership University */}
              </h2>
              <DescriptionCard paragraph={welcomeData?.description} />
              {welcomeData?.sectionNote.map((item) => {
                return (
                  <DescriptionCard heading={item.title} paragraph={item.desc} />
                );
              })}

              {/* <a href="#" className="btn btn_custom">
                University Overview
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
