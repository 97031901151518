import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { requestData } from "../../Utils/HttpClient";
import logoImg from "../../images/lgogo.png"
function Footer() {
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(document).on("mousemove", function (e) {
        var pointerX = e.pageX;
        var pointerY = e.pageY;
        window.$(".pointer").offset({
          top: pointerY,
          left: pointerX,
        });
      });
    });
  }, []);
  const [email, setEmail] = useState("");
  const apply = async (e) => {
    e.preventDefault();
    // console.log('first', email)
    if(email===""){
      toast.warn("Enter  email !!!");
      return
    }
    let emailRegex = /^([\-\.0-9a-zA-Z]+)@([\-\.0-9a-zA-Z]+)\.([a-zA-Z]){1,7}$/;
    if (!emailRegex.test(email)) {
      toast.warn("Enter a valid email !!!");
      return;
    }

    let res = await requestData("user/addSubscribe", "POST", {email});
    // console.log('res', res)
    if (res && res.status) {
      setEmail("")
      toast.success("Succesfully Subscribed !!!");
    } else {
      if (typeof res.message === "string") {
        toast.error(res.message);
      }
      if (typeof res.error === "string") {
        toast.error(res.error);
      }
    }
  };
  return (
    <>
      <footer id="footer-section">
        <ToastContainer/>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-12 col-md-12 col-12">
              <div className="footer-top">
                <h2 className="footer-title">
                  Get the latest VIP-SUCCESS news
                  <br />
                  delivered to you inbox
                </h2>
                <form className="form-subscribe" action="#">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control input-lg"
                      placeholder="Your email address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="input-group-btn">
                      <button
                        onClick={apply}
                        className="btn btn-success btn-lg"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row" style={{ padding: "0px 0" , alignItems:"center"}}>
            <div className="col-xl-3 col-lg-12 col-md-12 col-12">
              <div className="footer-widget text-center">
                <div className="footer-logo">
                  <img
                    src={logoImg}
                    alt="Logo"
                    className="img-fluid"
                  />
                </div>
                <div className="footer-address">
                  <p>West Palm Beach, FL, United States, Florida</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-xl-3 col-md-3 col-12">
              <div className="footer-widget text-center">
                <h3>Menu</h3>
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/centre-of-excellence">Centre Of Excellence</Link>
                  </li>
                  {/* <li>
                    <Link to="/admission">Admissions</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-xl-3 col-md-3 col-12">
              <div className="footer-widget text-center">
                <h3>Coaching Academy</h3>
                <ul>
                  {/* <li>
                    <Link to="/tution">Tution & Fees</Link>
                  </li> */}
                  <li>
                    <Link to="/faculty">Faculty</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-xl-3 col-md-3 col-12">
              <div className="social-icons text-center">
                <h3>Connect Us</h3>
                <ul>
                  <li>
                    <a href="">
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="fa-brands fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="fa-brands fa-youtube" />
                    </a>
                  </li>
                  <a href=""></a>
                </ul>
                <a href=""></a>
              </div>
              <a href=""></a>
            </div>
            <a href=""></a>
          </div>
          <a href=""></a>
        </div>
        <a href=""></a>
      </footer>
      <a>
        <div className="pointer" />
      </a>
    </>
  );
}

export default Footer;
