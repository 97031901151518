import React from 'react'
import a1 from "../../images/facts.jpg";
import a2 from "../../images/apply.jpg";
import a3 from "../../images/admission-bg.jpg";
import a4 from "../../images/financial.jpg";
import a5 from "../../images/rectangle-yellow.png";
import GoToTop from '../../component/Layout/Goto Top/GoToTop';
export default function Index() {
  return (
   
   <>
  <section className="admissoin_aid_area" style={{ backgroundImage: `url('${a3}')` }}>
    <div className="admissoin_overlay" />
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div>
            <div className="content">
              <h1>Admission &amp; Aid</h1>
              <p>
                We want to get to know you through your application, to hear
                your voice and explore your potential. We hope you get to know
                yourself better through the application process, too.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="admissoin_text_wraper">
    <div className="container">
      <div className="text_cnt">
        <p>
          When you decide to apply to The Georgetown Wesleyn University of
          Americas you will have the opportunity to explore courses across the
          programs and colleges that make up our university, crossing
          disciplines and charting your own unique academic path.
        </p>
        <p>
          We recognize that the application process might seem challenging;
          selecting the right undergraduate or graduate program is an important
          decision. Our admissions team is available to help you navigate this
          critical journey and make a choice that will affect your intellectual
          and creative potential in profound ways.
        </p>
      </div>
    </div>
  </section>
  <section className="facts_sec_area">
    <div className="container-fluid">
      <div className="main_cnt">
        <div className="image_area">
          <img src={a1} className="img-fluid" alt="facts-img" />
        </div>
        <div className="facts_title">
          <h2>FACTS</h2>
        </div>
      </div>
      <div className="count_sec">
        <div className="d-flex justify-content-around flex-wrap">
          <div className="box_main">
            <h2>148</h2>
            <p>
              Degree and diploma
              <br />
              programs offered
            </p>
          </div>
          <div className="box_main">
            <h2>90%</h2>
            <p>
              Of undergraduate <br />
              students receive <br />
              financial aid
            </p>
          </div>
          <div className="box_main">
            <h2>63%</h2>
            <p>
              of graduates had two or <br />
              more internships as <br />
              students
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="applyfor_sec_area">
    <div className="container-fluid">
      <div className="gapps">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-12 col-12 m-auto">
            <div className="left_cnt">
              <div className="head">
                <h2>Apply for 2022</h2>
              </div>
              <div className="text">
                <p>
                  The <strong>Fall 2022 application</strong> to The Georgetown
                  Wesleyn University of Americas is now open! Start your
                  application today and get connected to a counselor so you can
                  see if Kempbelle is the right place for you.
                </p>
              </div>
              <div className="howtoapply_btn">
                <a href="#" className="btn_custom">
                  How To Apply
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6 col-md-12 col-12">
            <div className="right_cnt" style={{ backgroundImage: `url('${a5}')`, backgroundPosition:"bottom right", backgroundRepeat:"no-repeat", backgroundSize:"auto" }}>
              
            </div>
            <div className="image_area">
                <img src={a2} className="img-fluid" alt="image" />
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="financial_aid_area">
    <div className="container-fluid">
      <div className="gapps">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
            <div className="left_cnt">
              <div className="image_area">
                <img
                  src={a4}
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-12 m-auto">
            <div className="right_cnt">
              <div className="head">
                <h2>Financial Aid</h2>
              </div>
              <div className="text">
                <p>
                  At Georgetown Wesleyn University of Americas, we prepare you
                  to launch your career by providing a supportive, creative, and
                  professional environment from which to learn practical skills,
                  build a network of industry contacts.
                </p>
              </div>
              <div className="howtoapply_btn">
                <a href="#" className="btn_custom">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="tuition_fees_sec">
    <div className="container-fluid">
      <div className="main-wrap gapps">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-12 bac-white m-auto pb-4">
            <div className="tution-wrap">
              <div className="icon">
                <i className="fa-solid fa-graduation-cap" />
              </div>
              <h2>Tution &amp; Fees</h2>
              <p>
                This new plan is designed to reduce the average cost of a Make
                School Education while preserving the core protections of ISAs –
                if you don’t have a job after Make School, you should not have
                to pay until you are employed.
              </p>
              <div className="detailed_btn">
                <a href="#" className="btn_custom">
                  Detailed Plans
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mb-4">
            <div className="box1">
              <h3>
                Tuition Costs,
                <br />
                2020-2022
              </h3>
              <div className="d-flex justify-content-between">
                <h5>Fall 2020</h5>
                <h5>$15,000</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>Spring 2021</h5>
                <h5>$15,000</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>Summer 2021</h5>
                <h5>$10,000</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>Fall 2021</h5>
                <h5>$15,000</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>Spring 2022</h5>
                <h5>$15,000</h5>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <h4>TOTAL</h4>
                <h4>$ 75,000</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mb-4">
            <div className=" box2">
              <h3>
                Tuition Costs,
                <br />
                2020-2022
              </h3>
              <div className="d-flex justify-content-between">
                <h5>Fall 2020</h5>
                <h5>$15,000</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>Spring 2021</h5>
                <h5>$15,000</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>Summer 2021</h5>
                <h5>$10,000</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>Fall 2021</h5>
                <h5>$15,000</h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>Spring 2022</h5>
                <h5>$15,000</h5>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <h4>TOTAL</h4>
                <h4>$ 75,000</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="future_Career" id="future_Career">
    <div className="form_SectionInner">
      <div className="container" style={{ maxWidth: 1260 }}>
        <div className="row">
          <div className="col-md-12">
            <div className="form_SEction">
              <h1 className="form_TExt">FORM</h1>
            </div>
            <div className="form_Inner">
              <h4 className="areYou">
                Are you ready to take the next step toward your future career?
              </h4>
              <button className="btn appliation_FOrm">
                Application Form <i className="fa-solid fa-arrow-right-long" />
              </button>
              <div className="d-flex aling-items-center justify-content-start justify-content-sm-center mt-4">
                <a href="" className="reques_BTN">
                  <span>Request Info</span>
                </a>
                <a href="" className="reques_BTN">
                  <span>Visit</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <GoToTop />
</>

   
  )
}
