import React, { useState, useEffect } from "react";
// import f1 from "../../images/contact_Baner.jpg";
// import f2 from "../../images/university-students-with-smart-mobile-phones-at-college-campus-teenager-friends-using-cellphones.jpg";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import MessageForm from "./MessageForm";
import { requestData } from "../../Utils/HttpClient";
import ContactDetail from "./ContactDetail";
import Intro from "../../component/Home/Intro/Intro";
import GoogleMap from "./GoogleMap";

export default function Index() {
  const [contactIntro, setContactIntro] = useState(null);
  const [contactDetail, setContactDetail] = useState(null);

  useEffect(() => {
    fetchContactIntroData();
  }, []);

  const fetchContactIntroData = async () => {
    const result = await requestData("user/viewContactUsIntro", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setContactIntro(result.data[0]);
      // console.log("AboutHis", result.data[0]);
    }
    fetchContactDetailData();
  };

  const fetchContactDetailData = async () => {
    const result = await requestData("user/viewContactDetails", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setContactDetail(result.data[0]);
      // console.log("AboutHis", result.data[0]);
    }
  };

  return (
    <>
      {/* <Intro
        bgImage={contactIntro?.bgImage}
        pageName={contactIntro?.pageName}
        description={contactIntro?.shortDesc}
      /> */}
      <ContactDetail contactDetail={contactDetail} />

      <section className="form_Contact">
        <div className="container" style={{ maxWidth: 1260 }}>
          <div className="row">
            <div className="col-md-6">
              <div className="followSocial">
                <h3 className="followSocial_title">Follow our social media</h3>
                {/* <p className="followSocial_Para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                  leo.
                </p> */}
                <div className="social-icons">
                  <ul>
                    <li>
                      <a href="">
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i className="fa-brands fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i className="fa-brands fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <MessageForm />
          </div>
        </div>
      </section>

      <GoogleMap data={contactDetail?.location} />
      <GoToTop />
    </>
  );
}
