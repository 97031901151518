import React from "react";

function Excellence({ excellienceCenter = null }) {
  return (
    <>
      <section className="grid_CentreofExceellence">
        <h3 className="title_MAin">Centre of Excellence </h3>
        <div className="container" style={{ maxWidth: 1260 }}>
          <div className="row">
            {excellienceCenter?.map((item, index) => {
              return (
                <div key={index} className="col-md-6 col-lg-4">
                  <h3 className="title">{item?.title}</h3>

                  <div className="content_box">
                    <p>
                      {item?.description}
                    </p>
                  </div>

                  <a href="/apply" className="btn btn_custom d-block">
                    Apply
                  </a>
                </div>
              );
            })}

            {/* <div className="col-md-6 col-lg-4">
              <h3 className="title">Working Professionals </h3>

              <div className="content_box">
                <p>
                  Anyone who is employed either part or full-time, Join our
                  academic programs or Centre of Excellence
                </p>
              </div>

              <a href="#" className="btn btn_custom d-block">
                Apply
              </a>
            </div>
            <div className="col-md-12 col-lg-4">
              <h3 className="title">Corporate </h3>

              <div className="content_box">
                <p>
                  We help you to cultivate and Grow Your Business, to be
                  inspired and provide new opportunities which fast track your
                  growth , purpose and prosperity. We work with Leadership Teams
                  – Boards - Learning & Organisational Development professionals
                </p>
              </div>

              <a href="#" className="btn btn_custom d-block">
                Apply
              </a>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Excellence;
