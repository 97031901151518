import React from "react";
import { Link } from "react-router-dom";

const Championship = ({ championshipData = null }) => {
    // console.log("championshipData", championshipData);
  return (
    <>
      <section className="student_Life" id="student_Life">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="student_Life_title_TExt">
                {/* STUDENT */}
                {championshipData?.heading}
              </h1>
              <div className="rotate_image">
                <div className="rotate_imageInner">
                  <img
                    src="images/shape-purple.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="com">
                <h4 className="out_Student">
                  {championshipData?.description}
                </h4>
                <div className="moreInfo">
                  <Link  to={'/champion'} className="more_InfoAnchor">
                    <span className="more_InfoTExt">More Information</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Championship;
