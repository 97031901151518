import React from "react";
import parse from 'html-react-parser';
import { Navigate } from "react-router-dom";

function Colaboration({ colaborationData = null }) {
  return (
    <>
      <section className="partner-with-us">
        <div className="container" >
          <h3>{colaborationData?.title}</h3>
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-12 col-12">
              <div className="partner-left">

                <div className="left-box">
                  <p className="para">
                    {parse(`${colaborationData?.description}`)}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-12">
              <div className="partner-right">
                {/* <button onClick={() => Navigate('/apply')} >
                  Apply here
                  <i className="fa-solid fa-arrow-right-long" />
                </button> */}
                <div className="contact-box">
                  <h4>{colaborationData?.heading}</h4>
                  <p>Partnerships and International Relations</p>
                  <p>
                    <strong>{colaborationData?.address}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Colaboration;
