import React, { useEffect, useState } from "react";
// import f1 from "../../images/faculty-banner.jpg";
// import f2 from "../../images/calvin.jpg";
// import f3 from "../../images/cordelia.jpg";
// import f4 from "../../images/eleanor.jpg";
// import f5 from "../../images/eva.jpg";
// import f6 from "../../images/lecturer-philosophy.jpg";
// import f7 from "../../images/luke.jpg";
// import f8 from "../../images/professor-history.jpg";
// import f9 from "../../images/professor-philosophy.jpg";
// import Faculty from "../Faculty/Index";
import { requestData } from "../../Utils/HttpClient";
const Directory = () => {
  const [directoryData, setDirectoryData] = useState(null);
  useEffect(() => {
    fetchDirectoryData();
  }, []);

  // console.log("DirData", directoryData);
  const fetchDirectoryData = async () => {
    const result = await requestData("user/viewFacultyList", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setDirectoryData(result.data[0]);
      // console.log("AboutHis", result.data[0]);
    }
  };
  return (
    <>
      {/* <Faculty /> */}
      <section id="faculty-directory">
        <div className="container">
          <h2>{directoryData?.title}</h2>
          <div className="row">
            {directoryData?.list.map((item, index) => {
              return (
                <div key={index} className="col-lg-4 col-md-6 col-12">
                  <div className="content">
                    <div className="parent_Image">
                      <div className="sec_Image">
                        <img className="img-fluid" src={item?.image} />
                        <div className="content-details fadeIn-bottom">
                          <p>{item?.desc}</p>
                          <h5>Contacts</h5>
                          <div className="contact">
                            <span className="phone-icon">
                              <i className="fa fa-phone" aria-hidden="true" />
                            </span>
                            {/* {console.log("item?.mobile", item?.mobile)} */}
                            <span className="phone-number">{`${item?.mobile
                              .toString()
                              .slice(0, 3)}-${item?.mobile
                                .toString()
                                .slice(3, 6)}-${item?.mobile
                                  .toString()
                                  .slice(6, 9)}`}</span>
                          </div>
                          <div className="mailing">
                            <span className="mail-icon">
                              <i className="fa-regular fa-envelope" />
                            </span>
                            <span className="mail-id">
                              <a href="rachel.gomez@email.com">{item?.email}</a>
                            </span>
                          </div>
                        </div>
                      </div>
                      <h3>{item?.name}</h3>
                      <p>{item?.designation}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Directory;
