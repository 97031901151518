import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

function index() {
  return (
    <Fragment>
    <Header/>
    <Outlet/>
    <Footer/>
    </Fragment>
  )
}

export default index