import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";

const News = ({ newsData = null, isNewspage = false }) => {
  return (
    <>
      <section className="news_andEvent" id="newsevent">
        <div className="container widthAdjustContainer">
          <div className="d-block d-sm-flex align-items-center justify-content-between mb-2">
            <h2 className="section_TItleNews">News &amp; Events</h2>
            {
              !isNewspage &&
              <Link to="/news" className="viewALlBTN">
                View All
              </Link>
            }

          </div>
          <div className="row mt-4 mt-md-0">
            {newsData?.map((item, index) => {
              return (
                <div key={index} className="col-md-6 col-lg-4 mb-4">
                  <div className="card_Custom">
                    <a href="#" className="card_customTitle">
                      {item?.title}
                    </a>
                    <span className="card_CustomDate">
                      {moment(item?.date).format('ll')}
                    </span>
                    <div className="card_Custom_Para">
                      {parse(item?.description)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
