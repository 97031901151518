import React, { useState, useEffect } from "react";
// import gal1 from "../../images/gallery_image1.jpg";
// import gal2 from "../../images/gallery_image2.jpg";
// import gal3 from "../../images/gallery_image3.jpg";
// import gal4 from "../../images/gallery_image4.jpg";
// import gal5 from "../../images/gallery_image5.jpg";
// import gal6 from "../../images/gallery_image6.jpg";
// import gal7 from "../../images/gallery_image7.jpg";
import imageStudent from "../../images/student_Image.jpg";
import about1 from "../../images/brydon-mccluskey-GvPceVqbxm4-unsplash.jpg";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import { requestData } from "../../Utils/HttpClient";
import EventGallery from "./EventGallery";
import Partnership from "../Home/Partnership";
import Intro from "../../component/Home/Intro/Intro";
import parse from "html-react-parser";
function Index() {
  const [galleryIntro, setGalleryIntro] = useState(null);
  const [photoGallery, setPhotoGallery] = useState(null);
  const [partnershipData, setPartnershipData] = useState(null);

  useEffect(() => {
    fetchGalleryIntroData();
  }, []);

  const fetchGalleryIntroData = async () => {
    const result = await requestData("user/viewGalleryIntro", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setGalleryIntro(result.data[0]);
    }
    fetchPhotoGalleryData();
  };

  const fetchPhotoGalleryData = async () => {
    const result = await requestData("user/viewPhotoGallery", "GET");
    if (result && result.status) {
      setPhotoGallery(result.data[0]);
    }
    fetchPartnershipData();
  };

  const fetchPartnershipData = async () => {
    const result = await requestData("user/viewPartnership", "GET");
    // console.log(result);
    if (result && result.status) {
      setPartnershipData(result.data[0]);
    }
  };

  return (
    <>
      <Intro
        bgImage={galleryIntro?.bgImage}
        pageName={parse(`${galleryIntro?.pageName}`)}
        description={parse(`${galleryIntro?.shortDesc}`)}
      />
      <EventGallery photoGallery={photoGallery} />

      <Partnership partnershipData={partnershipData} />

      <GoToTop />
    </>
  );
}

export default Index;
