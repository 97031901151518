import React from "react";
// import f1 from "../../images/contact_Baner.jpg";

function ContactDetail({ contactDetail = null }) {
  return (
    <>
      <section className="getTOuchSection">
        <div className="container" style={{ maxWidth: 1260 }}>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="contact_Content">
                <h4 className="title">{contactDetail?.title}</h4>
                <span className="wee_Would">
                  {contactDetail?.message}
                </span>
                <p className="para_DEs">
                    {contactDetail?.note}
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                  leo. */}
                </p>
                <div className="row">
                  <div className="col-md-12 col-lg-6 mb-3">
                    <div className="media">
                      <i className="fa-solid fa-location-dot all_Icons" />
                      <div className="media-body">
                        <h5 className="media_Title">Locations</h5>
                        <p className="media_Para">
                          {contactDetail?.address}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 mb-3">
                    <div className="media">
                      <i className="fa-solid fa-envelope all_Icons" />
                      <div className="media-body">
                        <h5 className="media_Title">Email us</h5>
                        <p className="media_Para">{contactDetail?.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 mb-3">
                    <div className="media">
                      <i className="fa-solid fa-phone-volume all_Icons" />
                      <div className="media-body">
                        <h5 className="media_Title">Call us</h5>
                        <p className="media_Para">Phone : {contactDetail?.phone}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-6 mb-4">
              <div className="contac_bannerImg">
                <img src={contactDetail?.image} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactDetail;
