import React, { useEffect, useState } from "react";

const FeesDetailsBox = ({ data,boxNum }) => {
  const [total, setTotal] = useState(0);
  useEffect(() => {
    let sum = 0;
    // console.log(" data?.feesDetails", data?.feesDetails);
    data?.feesDetails?.forEach((item, ind) => {
      sum += item.cost;
      // console.log("sum", sum);
    });
    setTotal(sum);
  }, []);
// console.log('sum', total)
  return (
    <>
      <div className="col-md-6" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div className={`${boxNum}`}>
          <a href="#">{data?.headingType}</a>
          <h3>
            {/* {data?.title.split(",")[0]}, */}
            <br />
            {data?.title.split(",")[1]}
          </h3>
          {data?.feesDetails?.map((item, index) => {
            return (
              <div key={index} className="d-flex costs">
                <h5>{item?.course}</h5>
                <h5>${item?.cost}</h5>
              </div>
            );
          })}
          <hr />
          <div className="d-flex costs">
            <h5>TOTAL</h5>
            <h5>$ {total}</h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeesDetailsBox;
