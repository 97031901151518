import React from 'react'
import imageStudent from "../../images/student_Image.jpg";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const Experience = ({ experience = null }) => {
  return (
    <section id="campus">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 col-xl-12 col-12 backggroundbcbg"
            style={{ background: "#ffc53a" }}
          >

            <div className="populated">
              <h1 className="campus-title">{experience?.heading}</h1>
              <div className='row'>
                <div className='col-8' style={{paddingRight: 20}}>
                  {parse(`${experience?.description}`)}
                </div>
                <div className='col-4'>
                  <div
                    className="yellowbgimgmain"
                    style={{ animationDuration: "3.07s" }}
                  >
                    <img src={experience?.image} className="animated" alt="" />
                  </div>
                </div>

              </div>

              <Link to="/centre-of-excellence" className="btn btn_custom">
                See for yourself
              </Link>
            </div>


          </div>
          {/* <div
              className="col-lg-2 col-xl-2 col-12 custom-image animated yellowbgimgmain"
              style={{ animationDuration: "3.07s" }}
            >
              <img src={imageStudent} className="animated" alt="" />
            </div> */}
        </div>
      </div>
    </section>
  )
}

export default Experience