import about1 from "../../images/brydon-mccluskey-GvPceVqbxm4-unsplash.jpg";
// import history_Person from "../../images/history_person.jpg";
// import history_Person_Two from "../../images/history_person_two.jpg";
import Form from "../../component/Form/Form";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { requestData } from "../../Utils/HttpClient";
import UniversityDetails from "./UniversityDetails";
import HistorySection from "../About/HistotySection";
import Intro from "../../component/Home/Intro/Intro";

const History = () => {
  const [aboutHistory, setAboutHistory] = useState(null);
  const [historyDetail, setHistoryDetail] = useState(null);
  const [universityDetail, setUniversityDetail] = useState(null);

  useEffect(() => {
    fetchAboutHistoryData();
  }, []);

  const fetchAboutHistoryData = async () => {
    const result = await requestData("user/viewIntroHistory", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setAboutHistory(result.data[0]);
      // console.log("AboutHis", result.data[0]);
    }
    fetchHistoryDetailData();
  };

  const fetchHistoryDetailData = async () => {
    const result = await requestData("user/viewHistorydetails", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setHistoryDetail(result.data[0]);
    }
    fetchUniversityDetailData();
  };

  const fetchUniversityDetailData = async () => {
    const result = await requestData("user/viewUniversityHistory", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setUniversityDetail(result.data[0]);
    }
  };

  return (
    <>
      <Intro
        bgImage={aboutHistory?.bgImage}
        pageName={aboutHistory?.pageName}
        description={aboutHistory?.shortDesc}
      />


      <HistorySection historyData={historyDetail} />

      <UniversityDetails universityDetail={universityDetail} />

      <Form />
      <GoToTop />
    </>
  );
};
export default History;
