import React from "react";
import parse from "html-react-parser";
function HistorySection({ historyData = null, marginTop = 120 }) {
  return (
    <>
      <section className="historyYears" style={{ marginTop }}>
        <div className="container">
          <div className="row align-items-center text">
            <div className="col-lg-5">
              <h3 className="titile_COunt">20+</h3>
            </div>
            <div className="col-lg-7">
              <p className="para_gRaph">{historyData?.title}</p>
            </div>
          </div>
          <div className="allPara">
            <p>{parse(`${historyData?.longDesc}`)}</p>
            {/* <p>
              The university has grown to include courses that reflect the
              founders’ interest in the emerging social sciences, international
              affairs, Business and philosophy, as well as innovation and
              management.
            </p>
            <p>
              Today the University prides itself on its partnerships and
              industry networks it has cultivated globally. Our Centre of
              Excellence is spearheading capacity building and hyper growth
              interventions which impacts each persons success and growth.{" "}
            </p> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default HistorySection;
