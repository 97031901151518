import React from "react";
// import about2 from "../../images/alexis-brown-Xv7k95vOFA-unsplash.jpg";
import parse from "html-react-parser";
function Mission({ missionData = null }) {
  return (
    <>
      <section id="campus" className="about-values">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-xl-12 col-12"
              style={{ background: "#ffc53a" }}
            >
              <div className="populated">
                <h1 className="value-title">
                  {missionData?.heading.split(" ")[0]} &amp;
                  
                  {missionData?.heading.split(" ")[2]}
                </h1>
                <p>{parse(`${missionData?.description}`)}</p>
                {/* <p>
                  <strong>
                    <i>Our mission has always focused on</i>
                  </strong>{" "}
                  Inspiring and amplifying self{" "}
                  <strong>
                    <i>
                      while maintaining a solid appreciation for the importance
                      of GROWTH - Humanity – Divinity and Life.
                    </i>
                  </strong>
                </p> */}
              </div>
            </div>
            {/* <div
              className="col-lg-2 col-xl-2 col-12 custom-image animated"
              style={{ animationDuration: "3.07s" }}
            >
              <img src={missionData?.image} className="animated" alt="" />
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Mission;
