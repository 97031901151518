import React from "react";

const GoogleMap = ({ data }) => {
  return (
    <section>
      <div className="" style={{ height: 460 }}>
        {data && (
          <iframe
            width="100%"
            height={460}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            src={`https://maps.google.com/maps?q=${data?.coordinates[1]}, ${data?.coordinates[0]}&z=15&output=embed`}
            frameborder="0"
          ></iframe>
        )}
      </div>
    </section>
  );
};

export default GoogleMap;
