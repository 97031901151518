import React from "react";

export const Information = ({ informationImg = null }) => {
  // console.log("informationImg", informationImg);

  
  return (
    <>
      <section className="elementor_sec_area">
        <div className="container" >
          <div className="elementor_cnt">
            {informationImg?.map((item, index) => {
              return (
                <div key={index} className="elementor_card1"
                style={{ backgroundImage: `url(${item.image})`, backgroundColor: `${item?.color}` }}
                >
                  <div className="elementor-widget">
                    <h2>{item?.heading}</h2>
                    <p>{item?.subheading}</p>
                  </div>
                {/* {
                  index == 0 ?
                  <div className="elementor-widget">
                    <h2>83%</h2>
                    <p>
                    Of Professionals had
                    <br/> immediate impact on career 
                    </p>
                  </div>:index==1?<div className="elementor-widget">
                    <h2>134</h2>
                    <p >
                    Hours of advanced content
                    </p>
                  </div>:index==2 ?<div className="elementor-widget">
                    <h2>#1</h2>
                    <p >
                    For faculty impact
                    </p>
                  </div>:""
                } */}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
