import React, { useEffect, useState } from 'react'
// import f1 from "../../images/faculty-banner.jpg";
// import f2 from "../../images/calvin.jpg";
// import f3 from "../../images/cordelia.jpg";
// import f4 from "../../images/eleanor.jpg";
// import f5 from "../../images/eva.jpg";
// import f6 from "../../images/lecturer-philosophy.jpg";
// import f7 from "../../images/luke.jpg";
// import f8 from "../../images/professor-history.jpg";
// import f9 from "../../images/professor-philosophy.jpg";
import Form from "../../component/Form/Form";
import GoToTop from '../../component/Layout/Goto Top/GoToTop';
import Directory from '../Faculty/Directory';
import { requestData } from "../../Utils/HttpClient";
function Graduate() {


    const [facultyData, setFacultyData] = useState(null);
    const [directoryData, setDirectoryData] = useState(null);

    useEffect(() => {
        fetchAboutFacultyData();
    }, []);

    const fetchAboutFacultyData = async () => {
        const result = await requestData("user/viewAboutIntro", "GET");
        // console.log("Bannerdata", result);
        if (result && result.status) {
            setFacultyData(result.data[0]);
            // console.log("AboutHis", result.data[0]);
        }
        fetchDirectoryData();
    };

    const fetchDirectoryData = async () => {
        const result = await requestData("user/viewFacultyList", "GET");
        // console.log("Bannerdata", result);
        if (result && result.status) {
            setDirectoryData(result.data[0]);
            // console.log("AboutHis", result.data[0]);
        }
    };
    return (
        <>
            <section className="faculty-missoin"  style={{ backgroundImage: `url(${facultyData?.bgImage})` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <div className="cnt">
                                <h1>{facultyData?.pageName}</h1>
                                    <p>
                                    {facultyData?.shortDesc}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='faculty_Section'>
                <div className='container'>
                <p className='para'>{facultyData?.longDesc}</p>
                </div>
            </section>


            {/* <section id="faculty-directory">
                <div className="container">
                    <h2>Faculty Directory</h2>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="content">
                                <div className="parent_Image">
                                    <div className="sec_Image">
                                        <img
                                            className="img-fluid"
                                            src={f2}
                                        />
                                        <div className="content-details fadeIn-bottom">
                                            <p>
                                                After graduating from Georgetown Wesleyn University of
                                                Americas with a MA in Communications, Rachel Gomez served as
                                                the director of Freshman and Transfer Programs before
                                                becoming the Vice President for Student Affairs. Holding
                                                both a BA and MA degree in Communications from Georgetown
                                                Wesleyn University of Americas, Rachel Gomez has spent much
                                                of her professional life at the College.
                                            </p>
                                            <h5>Contacts</h5>
                                            <div className="contact">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </span>
                                                <span className="phone-number">110-220-330</span>
                                            </div>
                                            <div className="mailing">
                                                <span className="mail-icon">
                                                    <i className="fa-regular fa-envelope" />
                                                </span>
                                                <span className="mail-id">
                                                    <a href="rachel.gomez@email.com">
                                                        rachel.gomez@email.com
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Rachel Gomez</h3>
                                    <p>Assistant Professor of History</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="content">
                                <div className="parent_Image">
                                    <div className="sec_Image">
                                        <img className="img-fluid" src={f3} />
                                        <div className="content-details fadeIn-bottom">
                                            <p>
                                                Franklin Doyle is an elected Fellow of the Academy of
                                                Behavioral Psychology Research, the premier honorary
                                                organization for scientists working at the interface of
                                                behavior and medicine, and he has been appointed to serve on
                                                two consensus committees at the Estudiar Academies of
                                                Sciences, Engineering, and Medicine. Outside of the academy,
                                                Doyle’s research has been cited in several amicus curiae
                                                briefs.
                                            </p>
                                            <h5>Contacts</h5>
                                            <div className="contact">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </span>
                                                <span className="phone-number">110-220-331</span>
                                            </div>
                                            <div className="mailing">
                                                <span className="mail-icon">
                                                    <i className="fa-regular fa-envelope" />
                                                </span>
                                                <span className="mail-id">
                                                    <a href="franklin.doyle@email.com">
                                                        franklin.doyle@email.com
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Franklin Doyle</h3>
                                    <p>Assistant Professor of History</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="content resNone">
                                <div className="parent_Image">
                                    <div className="sec_Image">
                                        <img
                                            className="img-fluid"
                                            src={f4}
                                        />
                                        <div className="content-details fadeIn-bottom">
                                            <p>
                                                Rodney Estrada is an Assistant Professor of Philosophy at
                                                Georgetown Wesleyn University of Americas. She has published
                                                over 125 peer-reviewed articles and book chapters, which
                                                have appeared in leading journals.Before coming to Estudiar,
                                                Rodney earned a B.A. from Carleton College (2006), an M.F.A.
                                                in philosophy from New York University (2010), and a Ph.D.
                                                in Literary Studies from the University of Wisconsin-Madison
                                                (2015).
                                            </p>
                                            <h5>Contacts</h5>
                                            <div className="contact">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </span>
                                                <span className="phone-number">110-220-332</span>
                                            </div>
                                            <div className="mailing">
                                                <span className="mail-icon">
                                                    <i className="fa-regular fa-envelope" />
                                                </span>
                                                <span className="mail-id">
                                                    <a href="dney.estrada@email.com">
                                                        dney.estrada@email.com
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Rodney Estrada</h3>
                                    <p>Lecturer in Philosophy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="content">
                                <div className="parent_Image">
                                    <div className="sec_Image">
                                        <img className="img-fluid" src={f4} />
                                        <div className="content-details fadeIn-bottom">
                                            <p>
                                                Calvin Foster is BA Journalism course leader and I teaches
                                                on the BA and the MA Magazine and MA journalism courses. He
                                                currently leads four modules – level 6 International
                                                Journalism Special Study, level 5 Journalism Research Paper,
                                                level 4 Journalism and the Wider World and MA Feature
                                                Writing. I also teach MA ethics and on a foundation module.
                                                Before joining Estudiar, he spent two decades working full
                                                time as a journalist including ten years at the Independent
                                                newspaper.
                                            </p>
                                            <h5>Contacts</h5>
                                            <div className="contact">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </span>
                                                <span className="phone-number">110-220-333</span>
                                            </div>
                                            <div className="mailing">
                                                <span className="mail-icon">
                                                    <i className="fa-regular fa-envelope" />
                                                </span>
                                                <span className="mail-id">
                                                    <a href="calvin.foster@email.com">
                                                        calvin.foster@email.com
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Calvin Foster</h3>
                                    <p>Lecturer in Journalism</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="content">
                                <div className="parent_Image">
                                    <div className="sec_Image">
                                        <img className="img-fluid" src={f5} />
                                        <div className="content-details fadeIn-bottom">
                                            <p>
                                                Professor Eva Willis received her Ph.D. from Cornell in 2005
                                                and began teaching at Princeton, coming to Georgetown
                                                Wesleyn University of Americas in 2009. Her interests
                                                include philosophy of mind and moral psychology, the nature
                                                of testimony, aesthetics and the philosophy of literature,
                                                and the later Wittgenstein. Eva Willis has recently taught
                                                courses on the above topics, and on speech-acts, philosophy
                                                of action, self-consciousness and intersubjectivity, and
                                                Marcel Proust.
                                            </p>
                                            <h5>Contacts</h5>
                                            <div className="contact">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </span>
                                                <span className="phone-number">110-220-334</span>
                                            </div>
                                            <div className="mailing">
                                                <span className="mail-icon">
                                                    <i className="fa-regular fa-envelope" />
                                                </span>
                                                <span className="mail-id">
                                                    <a href="eva.willis@email.com">eva.willis@email.com</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Eva Willis</h3>
                                    <p>Assistant Professor of Science and Philosophy</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="content resNone">
                                <div className="parent_Image">
                                    <div className="sec_Image">
                                        <img className="img-fluid" src={f6} />
                                        <div className="content-details fadeIn-bottom">
                                            <p>
                                                Hester Cox received her PhD from the University of
                                                Wisconsin, after which she taught at Illinois State
                                                University for three years before coming to Estudiar. Her
                                                research interests are in the areas of social and political
                                                philosophy and ethics. Her most sustained research projects
                                                concern political liberalism and political legitimacy,
                                                educational justice, and the gendered division of labor.
                                            </p>
                                            <h5>Contacts</h5>
                                            <div className="contact">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </span>
                                                <span className="phone-number">110-220-335</span>
                                            </div>
                                            <div className="mailing">
                                                <span className="mail-icon">
                                                    <i className="fa-regular fa-envelope" />
                                                </span>
                                                <span className="mail-id">
                                                    <a href="hester.cox@email.com">hester.cox@email.com</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Hester Cox</h3>
                                    <p>Professor of Politics</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="content">
                                <div className="parent_Image">
                                    <div className="sec_Image">
                                        <img className="img-fluid" src={f7} />
                                        <div className="content-details fadeIn-bottom">
                                            <p>
                                                Cordelia Nichols is the Chair and Professor of Jurisprudence
                                                at the University of Estudiar and a Professorial Fellow at
                                                University College. Before that she was a professor of
                                                philosophy at Rutgers University, New Brunswick, New Jersey
                                                in the United States. She has held visiting positions at the
                                                University of California, Los Angeles philosophy department
                                                and at the University of Chicago Law School.{" "}
                                            </p>
                                            <h5>Contacts</h5>
                                            <div className="contact">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </span>
                                                <span className="phone-number">110-220-336</span>
                                            </div>
                                            <div className="mailing">
                                                <span className="mail-icon">
                                                    <i className="fa-regular fa-envelope" />
                                                </span>
                                                <span className="mail-id">
                                                    <a href="cordelia.nichols @email.com">
                                                        cordelia.nichols @email.com
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Cordelia Nichols</h3>
                                    <p>Assistant Professor of Philosophy</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="content">
                                <div className="parent_Image">
                                    <div className="sec_Image">
                                        <img className="img-fluid" src={f8} />
                                        <div className="content-details fadeIn-bottom">
                                            <p>
                                                Luke Robertson is an intellectual historian specializing in
                                                twentieth-century Europe. He earned his BA in Mathematics
                                                and History at the University of Cambridge, and his PhD at
                                                Harvard University. Before coming to Estudiar, he taught for
                                                a decade at Drew University. Baring has held fellowships
                                                from the National Endowment of the Humanities, the ACLS, and
                                                the John Simon Guggenheim Foundation. At Estudiar he holds a
                                                joint appointment with the University Center for Human
                                                Values.
                                            </p>
                                            <h5>Contacts</h5>
                                            <div className="contact">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </span>
                                                <span className="phone-number">110-220-337</span>
                                            </div>
                                            <div className="mailing">
                                                <span className="mail-icon">
                                                    <i className="fa-regular fa-envelope" />
                                                </span>
                                                <span className="mail-id">
                                                    <a href="luke.robertson@email.com">
                                                        luke.robertson@email.com
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Luke Robertson</h3>
                                    <p>Assistant Professor of History</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="content ">
                                <div className="parent_Image">
                                    <div className="sec_Image">
                                        <img className="img-fluid" src={f9} />
                                        <div className="content-details fadeIn-bottom">
                                            <p>
                                                Hester Cox received her PhD from the University of
                                                Wisconsin, after which she taught at Illinois State
                                                University for three years before coming to Estudiar. Her
                                                research interests are in the areas of social and political
                                                philosophy and ethics. Her most sustained research projects
                                                concern political liberalism and political legitimacy,
                                                educational justice, and the gendered division of labor.
                                            </p>
                                            <h5>Contacts</h5>
                                            <div className="contact">
                                                <span className="phone-icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </span>
                                                <span className="phone-number">110-220-338</span>
                                            </div>
                                            <div className="mailing">
                                                <span className="mail-icon">
                                                    <i className="fa-regular fa-envelope" />
                                                </span>
                                                <span className="mail-id">
                                                    <a href="eleanor.parsons@email.com">
                                                        eleanor.parsons@email.com
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Eleanor Parsons</h3>
                                    <p>Lecturer in Philosophy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
              <Directory directoryData={directoryData} />
            <Form />
            <GoToTop />
        </>

    )
}

export default Graduate