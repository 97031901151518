import React, { useState } from "react";
import { Link } from "react-router-dom";
import FeesDetailsBox from "../Scholarship/Component/FeesDetailsBox";

const Tution = ({ feeData = null }) => {
  // const [fullFees, setFullFees] = useState(feeData[0])
  // console.log("FeeData", feeData);

  return (
    <>
      <section className="Tution">
        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="row">
            <div className="col-lg-5 col-md-12 col-12 bac-blue">
              <div className="tution-wrap">
                {/* <div className="elementory-icon">
                  <i className="fa-solid fa-graduation-cap" />
                  <span className="academic-fees">Academic Fees</span>
                </div> */}
                {/* <h2>Tution &amp; Fees</h2> */}
                <h2>VIP Passes</h2>

                {/* <Link to="/scholarship">
                  <button>
                    Scholarship
                    <i className="fa-solid fa-arrow-right-long" />
                  </button>
                </Link> */}

                {/* <a href="#">Detailed Plans</a> */}
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12 bac-white">
              <div className="main-wrap">
                <div className="row" style={{ justifyContent: "space-evenly" }}>
                  {feeData.map((item, ind) => {
                    return (
                      <FeesDetailsBox data={item} boxNum={`box${ind + 1}`} />
                    );
                  })}
                  {/* <FullFees fullFees={feeData} />
                  <Scholarship scholarship={feeData} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tution;
