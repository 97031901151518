import React from "react";
import parse from 'html-react-parser';

function Delivery({ deliveredData = null }) {
  return (
    <>
      <section className="delivered-section">
        <div className="container">
          <h2>{deliveredData?.heading}</h2>
          <div className="">
            <p>
              <strong>
                <i>{parse(`${deliveredData?.description}`)}</i>
              </strong>
               
            </p>
            {/* <p>
              <strong>Online – a fully interactive online program.</strong>
            </p>
            <p>
              <strong>
                Advanced CEI – This is a combination of online plus in person
                rich learning experiences:
              </strong>
            </p>
            <p>
              <strong>
                Classes include interactive elements such as audio, videos,
                industry networking, and group discussions.
              </strong>
            </p> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Delivery;
