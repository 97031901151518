import React, { useState, useEffect } from "react";
import Form from "../../component/Form/Form";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import { requestData } from "../../Utils/HttpClient";
import QuestionSection from "./QuestionSection";
// import Partnership from "./Partnership";
import parse from "html-react-parser";
import Intro from "../../component/Home/Intro/Intro";
import Partnership from "../Home/Partnership";
const Champion = () => {
    const [championData, setChampionData] = useState(null);
    const [partnershipData, setPartnershipData] = useState(null);

    useEffect(() => {
        fetchChampionData();
    }, []);

    const fetchChampionData = async () => {
        const result = await requestData("user/viewChampionsIntro", "GET");
        // console.log("Bannerdata", result);
        if (result && result.status) {
            setChampionData(result.data[0]);
            //   console.log("AboutHis", result.data[0]);
        }
        fetchPartnershipData();
    };

    const fetchPartnershipData = async () => {
        const result = await requestData("user/viewPartnership", "GET");
        // console.log(result);
        if (result && result.status) {
            setPartnershipData(result.data[0]);
        }
    };

    return (
        <>
            <Intro
                bgImage={championData?.bgImage}
                description={parse(`${championData?.shortDesc}`)}
                pageName={parse(`${championData?.pageName}`)}
            />

            <section className="championtextsecmain">
                <div className="container">
                    <div className="textchmpiononew">
                        {/* <p>
                        </p> */}
                        <p style={{ fontSize: "23px", fontWeight: "500",marginBottom:"0px" }}>
                            {parse(`${championData?.longDesc}`)}
                        </p>
                    </div>
                </div>
            </section>


            <QuestionSection championData={championData} />
            <Form />

            <Partnership partnershipData={partnershipData} />

            <GoToTop />
        </>
    );
};
export default Champion;
