import about1 from "../../images/brydon-mccluskey-GvPceVqbxm4-unsplash.jpg"
import Eventimage from "../../images/apply.jpg";
import Form from '../../component/Form/Form';
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
const Events = () => {
    return (
        <>
            <section className="about-missoin" style={{ backgroundImage: `url('${about1}')` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <div className="cnt">
                                    <h1>Events</h1>
                                    <p>
                                        The VIP SUCCESS University prepare you to launch your career by providing a supportive, creative, and professional.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="eventmission_Univercity">
                <div className="container-fluid" style={{ maxWidth: '1260px' }}>
                    <div className="row">
                        <div className="col-md-5">
                            <h4 className="sectionTItle">The VIP SUCCESS Mission</h4>
                        </div>
                        <div className="col-md-7">
                            <p className="para">VIP SUCCESS University is a distinguished public university, values learning in the liberal arts and sciences as a cornerstone of individual development and a vital foundation. We will ensure that our students develop both the skills that a sound education provides and the competencies essential for success and leadership in the emerging creative economy. We will also lead to generating practical and theoretical knowledge that enables people to better understand our world and improve conditions for local and global communities.</p>
                        </div>
                    </div>
                </div>
            </section>





            <section className="percetage_Number mb-0">
                <div className="container-fluid" style={{ maxWidth: '1260px' }}>
                    <div className="percetage_NumberInner">
                        <h3 className="h3_TExt">83%</h3>
                        <p className="para_TExt">of our students successfully graduate and begin their career development.</p>
                    </div>


                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="caption_Eventimage">
                                <i class="fa-solid fa-shield"></i>
                                <h3 className="py-4">Vision</h3>
                                <p>At VIP SUCCESS University, we will be the preeminent intellectual and creative center for effective engagement in a world that increasingly demands better-designed objects, communication, systems, and organizations to meet social needs.</p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="img_Event">
                                <img src={Eventimage} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>




                    <div className="vision_Para">
                        <p>Our vision aligns with shifts in the global economy, society, and environment, which animates our mission and our values:</p>
                    </div>


                    <div className="row py-5">
                        <div className="col-md-4 mb-3">
                            <div className="vision_Inner">
                                <h3 className="">01.</h3>
                                <p>Creativity and innovation to challenge the status quo will affect what and how we teach and the intellectual ambitions of the university itself.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="vision_Inner">
                                <h3>02.</h3>
                                <p>Social engagement should orient students’ academic experiences to help them become critically engaged citizens, dedicated to solving problems</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="vision_Inner">
                                <h3>03.</h3>
                                <p> VIP SUCCESS commitment to student success, important scholarship and creative activity, and public service sets it apart from other colleges.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Form />
            <GoToTop />
        </>
    )
}
export default Events;