import React from "react";
import parse from "html-react-parser";
export const Networking = ({ networkingData = null }) => {
  // console.log("Networking", networkingData);
  return (
    <>
      <section id="program-academy">
        <div className="container" >
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12 col-12">
              <h1
                className="campus-titlequckj text-center"
                // style={{ marginBottom: 0, padding: "30px 0" }}
              >
                {networkingData?.heading}
              </h1>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-12 col-12">
              <h3 className="academics">{networkingData?.title}</h3>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-12 col-12">
              <p className="network-para"
                style={{
                  margin: "40px auto",
                  maxWidth: "90%",
                  fontWeight: 400,
                  fontSize: 18,
                }}
              >
                {parse(`${networkingData?.description}`)}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
