import React, { useEffect, useState } from 'react'
// import f1 from "../../images/faculty-banner.jpg";
// import f2 from "../../images/calvin.jpg";
// import f3 from "../../images/cordelia.jpg";
// import f4 from "../../images/eleanor.jpg";
// import f5 from "../../images/eva.jpg";
// import f6 from "../../images/lecturer-philosophy.jpg";
// import f7 from "../../images/luke.jpg";
// import f8 from "../../images/professor-history.jpg";
// import f9 from "../../images/professor-philosophy.jpg";
import { requestData } from "../../Utils/HttpClient";
import Form from "../../component/Form/Form";
import GoToTop from '../../component/Layout/Goto Top/GoToTop';
import Directory from '../Faculty/Directory';
function Applyonline() {
    const [facultyData, setFacultyData] = useState(null);
    const [directoryData, setDirectoryData] = useState(null);

    useEffect(() => {
        fetchAboutFacultyData();
    }, []);

    const fetchAboutFacultyData = async () => {
        const result = await requestData("user/viewAboutIntro", "GET");
        // console.log("Bannerdata", result);
        if (result && result.status) {
            setFacultyData(result.data[0]);
            // console.log("AboutHis", result.data[0]);
        }
        fetchDirectoryData();
    };

    const fetchDirectoryData = async () => {
        const result = await requestData("user/viewFacultyList", "GET");
        // console.log("Bannerdata", result);
        if (result && result.status) {
            setDirectoryData(result.data[0]);
            // console.log("AboutHis", result.data[0]);
        }
    };
    return (
        <>
            <section className="faculty-missoin" style={{ backgroundImage: `url(${facultyData?.bgImage})` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <div className="cnt">
                                    <h1>{facultyData?.pageName}</h1>
                                    <p>
                                        {facultyData?.shortDesc}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='faculty_Section'>
                <div className='container'>
                    <p className='para'>{facultyData?.longDesc}</p>
                </div>
            </section>

            <Directory directoryData={directoryData} />

            <Form />
            <GoToTop />
        </>

    )
}

export default Applyonline