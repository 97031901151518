import React from 'react'
import globalimg from "../../images/globalimg.jpeg"
import moment from 'moment';
import flo from "../../images/flo-logo.png";
import { Link } from 'react-router-dom'

export default function global() {
  const loop = [1, 2, 3, 4];
  return (
    <>
      <section className="news-sec">
        <div className="container">
          <div class="top-heading-sec">
            <div class="sec-title">
              <h2 class="title-heading">Global Industry News<span className="heading-wrap">Powered By </span><img src={flo} /></h2>
            </div>
            <div class="view-all">
              <Link to="/news">View All</Link>
            </div>
          </div>
          <div className="news-wrap">
            {loop.map((item, index) => {
              return (
                <div className=" news-part global-cnt">
                  <div style={{ overflow: "hidden" }}>
                    <a href="#"><img src={globalimg} className="img-fluid transform"></img>
                      <div class="c-tile__content  global-wrap">
                        <div class="c-tile__meta">
                          <time class="c-tile__date">12/202/2000</time>
                        </div>
                        <h3 class="c-tile__headline">ihybuyv</h3>
                      </div>
                    </a>
                  </div>
                </div>
              );
            }

            )}

          </div>
        </div>
      </section>
    </>
  )
}
