import React, { useEffect, useState } from "react";
import parse from "html-react-parser"
function QuestionSection({ championData = null }) {



  return (
    <>
      <section className="experience">
        <div className="bac-img">
          {championData?.video && championData?.video === "video link" ? (
            <video autoPlay loop id="video-background">
              <source src={championData?.video} type="video/mp4" />
            </video>
          ) : null}

          {/* <img src="images/video-cover-home2-1.jpg" className="img-fluid" />
          <div className="play-button">
            <a href="#">
              <i className="fa-regular fa-circle-play" />
            </a>
          </div> */}
        </div>
        <div className="container">
          <div className="questionchampinontsxtpop">
            <h2>{parse(`${championData?.question}`)}</h2>
            <p>{parse(`${championData?.answer}`)}</p>
            {/* <p>
              Make professional connections for a future in a diverse workplace,
              being an ambassador helps prepare you for life with purpose. If
              this interests you, we invite you to talk to learn more.
            </p> */}
            {/* <a href="#" className="btn_custom">
                    Discover more
                    </a> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default QuestionSection;
