import React from "react";
import parse from 'html-react-parser'
function Question({ excellience = null }) {
  return (
    <>
      <section className=" businesses_partnering">
        <div className="container">
          <div className="businesses_partnering-content">
            <h2 className="businesses_partnering-overview">
              {excellience?.question}
            </h2>
            <div className="">
              <p className="para-one">
                {parse(`${excellience?.answer}`)}
                {/* <strong>
                  We work with the world’s best companies & inspire growth with
                  lasting impact. Unrivalled Career Growth & Award winning
                  applied learning.
                </strong> */}
              </p>
              {/* <p>
                Our strength in solving real-world business challenges and our
                experience of working with your senior stakeholders is
                unparallel. We are business practitioners who have worked with
                fortune businesses internationally and have a strong background
                in building Teams, Organisation Development and Business
                transformation.
              </p>
              <p>
                We build truly diverse talent pipelines, by identifying rising
                stars and equipping them with the skills of the future & help
                drive diversity in the board room.
              </p>
              <p>
                The faculty team are caring , dedicated and committed
                professionals whose common philosophy is to help you to excel.
              </p>
              <p>
                Our CEI Academy maintains a low teacher-to-student ratio as we
                strive for close, professional relationships between students
                and instructors. It is our goal to offer students an opportunity
                to experience and gain confidence in seeking guidance and
                support from those in leading positions.
              </p>
              <p>
                Our faculty are convinced that everyone has the right to develop
                his or her full potential, not matter of their income, financial
                status, age, sex, or profession. Their mission is to make the
                knowledge, tools and techniques available to everyone who
                desires to make progress towards personal excellence.
              </p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Question;
