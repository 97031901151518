import React, { useEffect, useState } from "react";
// import about1 from "../../images/brydon-mccluskey-GvPceVqbxm4-unsplash.jpg";
import StepOne from "../Donation/Step1";
import StepTwo from "../Donation/Step2";
import StepThree from "../Donation/Step3";
import StepFour from "../Donation/Step4";
import Multistep from "react-multistep";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import Intro from "../../component/Home/Intro/Intro";
import { requestData } from "../../Utils/HttpClient";
import { toast, ToastContainer } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

function Index() {
  const navigate = useNavigate();
  const [donation, setDonation] = useState(null);
  const [fundName, setfundName] = useState("");
  const [amount, setamount] = useState("");
  const [giftType, setgiftType] = useState("");
  const [country, setCountry] = useState("");
  const [state, setStatemain] = useState("");
  const [BiState, setBiState] = useState("");
  const [payICity, setpayICity] = useState("");
  const [payIState, setpayIState] = useState("");
  const [giftAnonymous, setgiftAnonymous] = useState("");
  const [memorialType, setmemorialType] = useState("");
  const [salutationPI, setsalutationPI] = useState("");
  const [unitedStateIP, setunitedStateIP] = useState("");
  const [statePI, setstatePI] = useState("");
  const [expirationMonthIP, setexpirationMonthIP] = useState("");
  const [expirationYearIP, setexpirationYearIP] = useState("");
  // const [formData, setFormData] = useState();

  useEffect(() => {
    getData();
  }, []);
  // console.log("state", state);
  const getData = async () => {
    let result = await requestData(`user/viewDonationIntro`, "GET", {});

    if (result && result.status) {
      setDonation(result.data[0]); // set data
    } else {
      toast.error(result.error || result.message);
    }
  };
  // console.log("amount", amount);
  const subMit = async (lastData) => {
    // console.log("lastData", lastData);
    let data = {
      donation,
      fundName,
      amount,
      giftType,
      comment: lastData.comment,
      city: lastData.city,
      country,
      email: lastData.email,
      // emailOptIn: lastData.emailOptIn,
      firstName: lastData.firstName,
      lastName: lastData.lastName,
      giftAnonymous: giftAnonymous,
      memorialName: lastData.memorialName,
      memorialType,
      password: lastData.password,
      state,
      streetAddress: lastData.streetAddress,
      streetAddress2: lastData.streetAddress2,
      zipcode: lastData.zipcode,
      businessInformation: {
        employerName: lastData.employerNameBI,
        jobTitle: lastData.jobTitleBI,
        country: lastData.countryBI,
        streetAddress: lastData.streetAddressBI,
        streetAddress2: lastData.streetAddress2BI,
        city: lastData.cityBI,
        state: BiState,
        zipcode: lastData.zipcodeBI,
      },
      partnerInformation: {
        salutation: salutationPI,
        firstName: lastData.firstNamePI,
        middleName: lastData.middleNamePI,
        lastName: lastData.lastNamePI,
        email: lastData.emailPI,
      },
      paymentInformation: {
        firstName: lastData.firstNameIP,
        lastName: lastData.lastNameIP,
        cardHolderEmail: lastData.cardHolderEmailIP,
        unitedState: unitedStateIP,
        streetAddress: lastData.streetAddressIP,
        streetAddress2: lastData.streetAddress2IP,
        city: lastData.cityPI,
        state: statePI,
        zipcode: lastData.zipcodePI,
        creditCardNumber: lastData.creditCardNumberIP,
        verificationCode: lastData.verificationCodeIP,
        expirationMonth: expirationMonthIP,
        expirationYear: expirationYearIP,
      },
    };
    // console.log("data", data);

    let validateObj = {
      donation,
      fundName,
      amount,
      giftType,
      comment: lastData.comment,
      city: lastData.city,
      country,
      email: lastData.email,
      // emailOptIn: lastData.emailOptIn,
      firstName: lastData.firstName,
      lastName: lastData.lastName,
      giftAnonymous: giftAnonymous,
      memorialName: lastData.memorialName,
      memorialType,
      password: lastData.password,
      state,
      streetAddress: lastData.streetAddress,
      streetAddress2: lastData.streetAddress2,
      zipcode: lastData.zipcode,
      ...data.businessInformation,
      ...data.partnerInformation,
      ...data.paymentInformation,
    };
    // console.log(Object.entries(validateObj));
    const testValid = Object.entries(validateObj).find((it) => it[1] === "");
if(testValid){
  toast.warn(testValid[0]+" is required!!!");
  return;
}
    // console.log("dataaa", testValid);

    // const isValid = Object.values(validateObj).some((val) => val === "");
    // if (isValid) {
    //   toast.warn("all field are required!!!");
    //   return;
    // }

    let res = await requestData("user/addGiveAsDonation", "POST", data);
    if (res && res.status) {
      toast.success("donation added successfully");

      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      toast.error(res.message || res.error);
    }
    // console.log("res", res);
  };
  const steps = [
    { title: "First Step", component: <StepOne callMack={setfundName} /> },
    {
      title: "Second Step",
      component: <StepTwo callMack={setamount} state={amount} />,
    },
    {
      title: "Third Step",
      component: <StepThree callMack={setgiftType} state={giftType} />,
    },
    {
      title: "Fourth Step",
      component: (
        <StepFour
          callMack={subMit}
          setCountre={setCountry}
          setStatemain={setStatemain}
          setBiState={setBiState}
          setpayICity={setpayICity}
          setpayIState={setpayIState}
          setgiftAnonymous={setgiftAnonymous}
          setmemorialType={setmemorialType}
          setsalutationPI={setsalutationPI}
          setunitedStateIP={setunitedStateIP}
          setstatePI={setstatePI}
          setexpirationYearIP={setexpirationYearIP}
          setexpirationMonthIP={setexpirationMonthIP}
        />
      ),
    },
  ];
  // console.log("statePI", statePI);
  return (
    <>
      <Intro
        bgImage={donation?.bgImage}
        pageName={donation?.pageName}
        description={donation?.shortDesc}
      />
      <ToastContainer />
      <section id="donation-form">
        <div className="container">
          <div className="row">
            <div className="container-form">
              <Multistep activeStep={0} showNavigation={true} steps={steps} />
            </div>
          </div>
        </div>
      </section>

      <GoToTop />
    </>
  );
}

export default Index;
