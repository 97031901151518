import React from "react";
import { useNavigate } from "react-router-dom";

const ScholarshipTable = ({ data }) => {
  const navigate=useNavigate()
  // console.log("data", data);
  return (
    <section className="scholarship-table">
      <div className="container">
        <div className="table-content">
          <div className="table-responsive">
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" className="table-heading">
                    DATES
                  </th>
                  <th scope="col" className="table-heading">
                    DAY/TIME
                  </th>
                  <th scope="col" className="table-heading">
                    DELIVERY
                  </th>
                  <th scope="col" className="table-heading">
                    CAMPUS
                  </th>
                  <th scope="col" className="table-heading">
                    COST
                  </th>
                  <th scope="col" className="table-heading">
                    AVAILABILITY
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, ind) => {
                  return (
                    <tr>
                      <th scope="row" className="table-dates">
                        <p>
                          <b>Start Date:</b>
                          {item.Dates.startDate}
                        </p>
                        <p>
                          <b>End Date:</b> {item.Dates.endDate}
                        </p>
                      </th>
                      <td className="table-dash">{item.daytime}</td>
                      <td className="table-link">
                        <span>
                          <a href="#">{item.delivery}</a>
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </span>
                      </td>
                      <td className="table-online">{item.campus}</td>
                      <td className="table-cost">${item.cost}</td>
                      <td className="table-register">
                        <button onClick={()=>navigate("/apply-scholarship")} className="register">
                          REGISTER{" "}
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScholarshipTable;
