import { useState, useEffect, Suspense } from "react";
import React from "react";
import { Link } from "react-router-dom";
import Form from "../../component/Form/Form";
// import video from "../../images/videos/Intro-Short-Video-Edinboro-University.mp4";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import imageStudent from "../../images/student_Image.jpg";
import { requestData } from "../../Utils/HttpClient";
import Welcome from "./Welcome";
import Experience from "./Experience";
import Banner from "./Banner";
// import bgimage from "../../images/mob-res.png";
import { Quickview } from "./Quickview";
import { ApplySection } from "./ApplySection";
import { Scholarship } from "./Scholarship";
import { Information } from "./Information";
import { Networking } from "./Networking";
import Tution from "./Tution";
import Championship from "./Championship";
import ChampionshipImage from "./ChampionshipImage";
import News from "./News";
import Partnership from "./Partnership";
import OurMoto from "./OurMoto";
import "./Home.css"
function Index() {
  const [welcomeData, setWelcomeData] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  const [experience, setExperience] = useState(null);
  const [quickViewData, setQuickViewData] = useState(null);
  const [applyData, setApplyData] = useState(null);
  const [scholarshipData, setScholarshipData] = useState(null);
  const [informationImg, setInformationImg] = useState(null);
  const [networkingData, setNetworkingData] = useState(null);
  const [feeData, setFeeData] = useState([]);
  const [championshipData, setChampionshipData] = useState(null);
  const [newsData, setNewsData] = useState(null);
  const [partnershipData, setPartnershipData] = useState(null);

  useEffect(() => {
    fetchWelcomeData();
  }, []);


  const fetchWelcomeData = async () => {
    const result = await requestData("user/viewWelcomeSection", "GET");
    // console.log(result);
    if (result && result.status) {
      setWelcomeData(result.data[0]);
    }
    fetchExperienceData();
  };

  const fetchExperienceData = async () => {
    const result = await requestData("user/viewExperienceSection", "GET");
    console.log(result);
    if (result && result.status) {
      setExperience(result.data[0]);
    }
    fetchQuickviewData();
  };

  const fetchQuickviewData = async () => {
    const result = await requestData("user/getQuickView", "GET");
    // console.log(result);
    if (result && result.status) {
      let arr = result.data[0];
      let color = ["#2b7258", "#ffdb49", "#466bb8", "rgb(184 70 70)"];
      color.forEach((item, index) => {
        arr.category[index].color = item;
      });
      setQuickViewData(arr);
    }
    fetchApplyData();
  };

  // console.log("ArrData", quickViewData);

  const fetchApplyData = async () => {
    const result = await requestData("user/viewApplySection", "GET");
    // console.log(result);
    if (result && result.status) {
      setApplyData(result.data[0]);
    }
    fetchScholarshipData();
  };

  const fetchScholarshipData = async () => {
    const result = await requestData("user/viewScholarship", "GET");
    // console.log(result);
    if (result && result.status) {
      setScholarshipData(result.data[0]);
    }
    fetchInformationData();
  };

  const fetchInformationData = async () => {
    const result = await requestData("user/viewImage", "GET");
    console.log(result);
    if (result && result.status) {
      let arr = result.data.reverse();
      let color = ["#36348e", "#ffc53a", "#cdd4fb"];
      color.forEach((item, index) => {
        arr[index].color = item;
      });
      setInformationImg(arr);
    }
    fetchNetworkingData();
  };

  // console.log("Img", informationImg);

  const fetchNetworkingData = async () => {
    const result = await requestData("user/viewNetworking", "GET");
    // console.log(result);
    if (result && result.status) {
      setNetworkingData(result.data[0]);
    }
    fetchFeeData();
  };

  const fetchFeeData = async () => {
    const result = await requestData("user/viewTutionFees", "GET");
    // console.log(result);
    if (result && result.status) {
      setFeeData(result.data);
    }
    fetchChampionshipData();
  };

  const fetchChampionshipData = async () => {
    const result = await requestData("user/viewChampionsLife", "GET");
    // console.log(result);
    if (result && result.status) {
      setChampionshipData(result.data[0]);
    }
    fetchNewsData();
  };

  const fetchNewsData = async () => {
    const result = await requestData("user/viewNewsAndEvent", "GET");
    // console.log(result);
    if (result && result.status) {
      setNewsData(result.data.slice(0, 3));
    }
    fetchPartnershipData();
  };

  const fetchPartnershipData = async () => {
    const result = await requestData("user/viewPartnership", "GET");
    // console.log(result);
    if (result && result.status) {
      setPartnershipData(result.data[0]);
    }
  };
  // console.log("feeData", feeData);
  return (
    <>
      <Banner />

      {/* Welcome Section */}
      <Suspense fallback={<h1>...loadding</h1>}>
        <Welcome />
      </Suspense>

      {/* Experience Section  */}
      <Experience experience={experience} />
      {/* OurMoto */}
      <OurMoto />
      {/* Information Section  */}
      <Information informationImg={informationImg} />

      {/* Apply Section  */}
      <ApplySection applyData={applyData} />

      {/* Quickview Section  */}
      <Quickview quickViewData={quickViewData} />



      {/* Scholarship Section  */}
      <Scholarship scholarshipData={scholarshipData} />

      {/* Networking Section  */}
      <Networking networkingData={networkingData} />

      {/* Tution Section  */}
      <Tution feeData={feeData} />

      {/* Championship Section  */}
      <Championship championshipData={championshipData} />

      {/* Championship Image Section  */}
      <ChampionshipImage championshipImage={championshipData} />

      {/* News Section  */}
      <News newsData={newsData} />

      {/* <section className="experience">
        <div className="bac-img">
          <img src="images/video-cover-home2-1.jpg" className="img-fluid" />
          <div className="play-button">
            <a href="#">
              <i className="fa-regular fa-circle-play" />
            </a>
          </div>
        </div>
        <div className="container">
          <div className="content">
            <h2>
              Studying at  <br />
              GWUA
            </h2>
            <p>
              GWUA Leadership University offers you industry leading organizational & management-oriented curriculum and programs.
            </p>
            <p>
              Whether you are looking to start a career or want to progress your career in management or Entrepreneurship we offer you the best opportunity to get trained by Global industry faculty who are experienced & Successful experts in their field.
            </p>
            <p>
              We pride ourselves on establishing growth pathways which helps you to integrate prior experience whilst empowering you grow.
            </p>
            <p>
              GUA helps each student/participant to think entrepreneurially, whilst also ensuring that we engage and inspire you through advance teaching coaching techniques, We are pioneering a new take on higher education and leadership development.
            </p>
            <a href="#" className="btn_custom">
              Discover more
            </a>
          </div>
        </div>
      </section> */}
      <Form />

      {/* Partnership Section  */}
      <Partnership partnershipData={partnershipData} />

      <GoToTop />
    </>
  );
}

export default Index;
