import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { requestData } from "../../../../Utils/HttpClient";
import parse from "html-react-parser";
const OverViewAndOpp = () => {
  const [overView, setOverView] = useState();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await requestData(`user/viewScholarshipOverview`, "GET", {});

    if (result && result.status) {
      setOverView(result.data[0]); // set data
    } else {
      toast.error(result.error || result.message);
    }
  };
  // console.log("overView", overView);
  return (
    <section className="scholarship-text">
      <div className="container">
        <div className="scholarship-content" style={{ marginTop: "30px" }}>
          <h2 className="scholarships-overview">{overView?.title}</h2>
          <div className="text">
            {parse(`${overView?.description}`)}{" "}
            {parse(`${overView?.donationDesc}`)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverViewAndOpp;
