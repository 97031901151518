import React, { useState, useEffect } from 'react'
import c1 from "../../images/iStock-1165677324.jpg";
// import c2 from "../../images/gallery.jpg";
// import c3 from "../../images/gallery1.jpg";
// import c4 from "../../images/gallery2.jpg";
// import c5 from "../../images/gallery3.jpg";
// import c6 from "../../images/gallery4.jpg";
// import c7 from "../../images/graduate.jpg";
// import c8 from "../../images/mission.jpg";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import { requestData } from '../../Utils/HttpClient';
import MiddleSection from './MiddleSection';
import Question from './Question';
import Excellence from './Excellence';
import Delivery from './Delivery';
export default function Index() {

    const [excellience, setExcellience] = useState(null)
    const [excellienceCenter, setExcellienceCenter] = useState(null)
    const [deliveredData, setDeliveredData] = useState(null)
// console.log('excellience', excellience.longDesc)
    useEffect(() => {
        fetchPartnershipData()
    }, [])


    const fetchPartnershipData = async () => {
        const result = await requestData("user/viewCIEIntro", "GET");
        // console.log(result);
        if (result && result.status) {
            setExcellience(result.data[0]);
        }
        fetchExcellenceData()
    };

    const fetchExcellenceData = async () => {
        const result = await requestData("user/viewApplyCEI", "GET");
        // console.log(result);
        if (result && result.status) {
            setExcellienceCenter(result.data);
        }
        fetchDeliveryData()
    };

    const fetchDeliveryData = async () => {
        const result = await requestData("user/viewDeliveryMode", "GET");
        // console.log(result);
        if (result && result.status) {
            setDeliveredData(result.data[0]);
        }
    };

    return (
        <>
            <section className="academics_Section" style={{ backgroundImage: `url('${excellience?.bgImage}')` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <div className="cnt">
                                    <h1>{excellience?.pageName}</h1>
                                    <p>
                                        {/* Our CEI is open to budding stars as well as professionals. Our goal is help you transform so that you get a deeper understanding of who you are and your purpose this lifetime. */}
                                        {excellience?.shortDesc}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="majors_Section">
                <div className="container">
                    <div className="majors_Section_Inner">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                {/* <h4 className="media_BodyTitle_Section m-0">
                                    Realize Your Greatest Dreams with your Highest Self. 
                                </h4> */}
                            </div>
                            <div className="col-12">
                                {/* <p className="para_Des">
                                    We help you to cultivate and Grow, to be inspired and provide new opportunities which fast track your growth , purpose and prosperity.

                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="photo_CollageSection">
                <div className="container" style={{ maxWidth: 1260 }}>
                    <div className="row">
                        <div className="col-md-4 col-12 customgallery_Column">
                            <div className="imag_Gallery">
                                <img src={c2} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-4 col-12 customgallery_Column">
                            <div className="imag_Gallery">
                                <img src={c3} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-4 col-12 customgallery_Column">
                            <div className="imag_Gallery">
                                <img src={c4} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-4 col-12 customgallery_Column">
                            <div className="imag_Gallery">
                                <img src={c5} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-4 col-12 offset-md-4 customgallery_Column">
                            <div className="imag_Gallery">
                                <img src={c6} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <MiddleSection excellience={excellience} />


            <Question excellience={excellience} />

            <Excellence excellienceCenter={excellienceCenter} />
            
            <Delivery deliveredData={deliveredData} />

            {/* <section className="graduate_Section">
                <div className="container-fluid p-0">
                    <div className="position-relative graduate_SectionImg">
                        <img src={c7} alt="" className="img-fluid" />
                    </div>
                    <div className="container" style={{ maxWidth: 1260 }}>
                        <div className="abslute_Caption">
                            <h4 className="graduate_TExt">Graduate Areas of Study </h4>
                            <div className="row graduate_AreasofStudyCaptionsMAin">
                                <div className="col-md-6">
                                    <div className="graduate_AreasofStudyCaptions">
                                        <ul>
                                            <li>
                                                <a href="" className="">
                                                    {" "}
                                                    Business &amp; Administration{" "}
                                                    <i className="fa-solid fa-arrow-right-long ml-3" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="" className="">
                                                    {" "}
                                                    Management{" "}
                                                    <i className="fa-solid fa-arrow-right-long ml-3" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="graduate_AreasofStudyCaptions">
                                        <ul>
                                            <li>
                                                <a href="" className="">
                                                    {" "}
                                                    Sciences <i className="fa-solid fa-arrow-right-long ml-3" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="" className="">
                                                    {" "}
                                                    Art &amp; Design{" "}
                                                    <i className="fa-solid fa-arrow-right-long ml-3" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mission_ValueSection">
                <div className="container" style={{ maxWidth: 1260 }}>
                    <div className="row custom_Row align-items-center">
                        <div className="col-lg-8">
                            <div className="mission_Section_IMG">
                                <h4 className="misssion_TExt">Mission &amp; Values</h4>
                                <p className="mission_Para mb-4">
                                    We are and will be a university where design and social research
                                    drive approaches to studying issues of our time, such as democracy,
                                    urbanization, technological change, economic empowerment,
                                    sustainability, migration, and globalization.
                                </p>
                                <a href="" className="btn_custom mt-4">
                                    Learn More
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mission_Valuesimg">
                                <img src={c8} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <GoToTop />

        </>
    )
}
