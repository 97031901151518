import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { requestData } from "../../Utils/HttpClient";
import logoImg from "../../images/lgogo.png"
import VIP_logo_hh from "../../images/VIP_logo_hh.png"
function Header() {
  const [appDropDown, setAppDropdown] = useState([]);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);
  useEffect(() => {
    window.$(document).on("scroll", function () {
      if (window.$(document).scrollTop() > 86) {
        window.$("#custom_Header").addClass("shrink");
      } else {
        window.$("#custom_Header").removeClass("shrink");
      }
    });
  }, []);

  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const closeNavbar = () => {
    setIsNavbarCollapsed(false);
  };

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await requestData(`user/viewAdmissionDropDown`, "GET", {});
    console.log('addResult', result)
    if (result && result.status) {
      setAppDropdown(result.data); // set data
    } else {
      toast.error(result.error || result.message);
    }
  };
  const navigate = useNavigate();
  const admissionPage = (data) => {
    // console.log("data ", data);

    if (data !== "Scholarship And Grants") {
      navigate(`/admission/${data}`, { state: data });
    } else {
      navigate("/scholarship");
    }
  };
  return (
    <header>
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="custom_Header"
      >
        <div className="container-fluid">
          {/* Brand */}
          <a className="navbar-brand navbarheaderlogoimg" href="#">
            <Link to="/">
              <img
                src={VIP_logo_hh}
                alt="Logo"
                className="img-fluid"
                // style={{ maxWidth: 300 }}
              />
            </Link>
          </a>
          {/* Toggler/collapsibe Button */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="collapsibleNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Navbar links */}
          <div  className={`navbar-collapse ${!isNavbarCollapsed ? 'collapserespmnse' : ''}`} id="collapsibleNavbar">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/" onClick={toggleNavbar}>
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbardrop1"
                  data-toggle="dropdown"
                  // onClick={toggleNavbar}
                >
                  About
                </a>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/about"      onClick={toggleNavbar}>
                    About
                  </Link>
                  <Link className="dropdown-item" to="/history" onClick={toggleNavbar}>
                    History
                  </Link>
                  <Link className="dropdown-item" to="/news" onClick={toggleNavbar}>
                    News
                  </Link>
                  <Link className="dropdown-item" to="/faculty"onClick={toggleNavbar}>
                    Faculty
                  </Link>
                  {/* <Link className="dropdown-item" to="/events">
                    Events
                  </Link> */}
                  {/* <Link className="dropdown-item" to="/about">
                    Delivery
                  </Link> */}
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbardrop"
                  data-toggle="dropdown"
                  // onClick={toggleNavbar}
                >
                  {/* Student Life */}
                  Partner
                </a>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/champion" onClick={toggleNavbar}>
                    Champion
                  </Link>
                  <Link className="dropdown-item" to="/gallery" onClick={toggleNavbar}>
                    Photo Gallery
                  </Link>
                  <Link className="dropdown-item" to="/partnerShip" onClick={toggleNavbar}>
                    Partnership
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbardrop"
                  data-toggle="dropdown"
                  onClick={toggleNavbar}
                >
                  Centre Of Excellence
                </a>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/centre-of-excellence" onClick={toggleNavbar}>
                    About CIE
                  </Link>
                  <Link className="dropdown-item" to="/our-goal" onClick={toggleNavbar}>
                    Our Goals
                  </Link>
                  {/* <Link className="dropdown-item" to="/centre-of-excellence">
                    Faculty
                  </Link> */}
                  <Link className="dropdown-item" to="/scholarship" onClick={toggleNavbar}>
                    Scholarship
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbardrop"
                  data-toggle="dropdown"
                  onClick={toggleNavbar}
                >
                  Admissions
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="navbar_Content">
                          <h3 className="title">Academics</h3>
                          <ul className="parentDrop">
                            <li>
                              <a >
                                <i class="fa-solid fa-angles-right"></i>{" "}
                                {appDropDown[0]?.title}
                              </a>
                              <ul className="childrenDrop">
                                {appDropDown[0]?.category?.map((item, ind) => {
                                  return (
                                    <li key={ind}>
                                      <a onClick={() => admissionPage(item)}>
                                        <i class="fa-solid fa-angle-right"></i>{" "}
                                        {item}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                            <li>
                              <a href="">
                                <i class="fa-solid fa-angles-right"></i>
                                {appDropDown[1]?.title}
                              </a>
                              <ul className="childrenDrop">
                                {appDropDown[1]?.category?.map((item, ind) => {
                                  return (
                                    <li key={ind}>
                                      <a onClick={() => admissionPage(item)}>
                                        <i class="fa-solid fa-angle-right"></i>{" "}
                                        {item}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="navbar_Content">
                          <h3 className="title">Admissions</h3>
                          <ul className="parentDrop">
                            <li>
                              <a>
                                <i class="fa-solid fa-angles-right"></i>{" "}
                                {appDropDown[2]?.title}
                              </a>

                              <ul className="childrenDrop">
                                {appDropDown[2]?.category?.map((item, ind) => {
                                  return (
                                    <li key={ind}>
                                      <a onClick={() => admissionPage(item)}>
                                        <i class="fa-solid fa-angle-right"></i>{" "}
                                        {item}
                                      </a>
                                    </li>
                                  );
                                })}

                                {/* <li>
                                  <Link to='/graduateadmissions'><i class="fa-solid fa-angle-right"></i> Graduate Admissions</Link>
                                </li>
                                <li>
                                  <Link to='/scholarship'><i class="fa-solid fa-angle-right"></i> Scholarships and Grants</Link>
                                </li>
                                <li>
                                  <Link to='/coursefinder'><i class="fa-solid fa-angle-right"></i> Course Finder</Link>
                                </li>
                                <li>
                                  <Link to='/applyonline'><i class="fa-solid fa-angle-right"></i>  Apply Online</Link>
                                </li> */}
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <Link className="dropdown-item" to="/admission">
                    Academic
                  </Link>
                  <Link className="dropdown-item" to="/admission">
                    Scholarship
                  </Link> */}
                </div>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/contact" onClick={toggleNavbar}>
                  Contact Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link Button Button--orange" to="/donation" onClick={toggleNavbar}>
                  Donation
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
