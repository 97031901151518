import React from "react";

export const ApplySection = ({ applyData = null }) => {
  // console.log("Apply", applyData);
  return (
    <>
      <section className="apply_Section" id="applySection">
        <div className="container custom_Container">
          <h1 className="apply_SectionTitle">{applyData?.heading}</h1>
          {applyData?.sectionNote.map((item, index) => {
            return (
              <>
                <div key={index} className="row customApplyROw">
                  <div className="col-md-1 col-2 mb-3 mb-md-0 apply-responsive">
                    <span className="counting_Applytext">{index + 1}</span>
                  </div>
                  <div className="col-md-3 col-10 mb-3 mb-md-0 apply-responsive">
                    <span className="counting_ApplyBTN">{item?.title}</span>
                  </div>
                  <div className="col-md-8">
                    <p className="apply_TExtPara">{item?.desc}</p>
                  </div>
                </div>
                {applyData?.sectionNote.length === (index+1) ? "" : <hr className="apply_HR" />}
              </>
            );
          })}

          <hr className="apply_HR" />
          {/* <div className="mt-2 mt-md-5">
            <a href="" className="view_AlLREqBTN">
              View All Requirements{" "}
            </a>
          </div> */}
        </div>
      </section>
    </>
  );
};
