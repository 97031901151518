import React, { Fragment } from "react";

function DescriptionCard({ heading = "", paragraph = "" }) {
  return (
    <Fragment>
      <h4>{heading}</h4>
      <p className="entry-para">{paragraph}</p>
    </Fragment>
  );
}

export default DescriptionCard;
