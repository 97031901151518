import React, { useState, useEffect } from "react";
import { json, Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { fileUplode, requestData } from "../../Utils/HttpClient";
import GoToTop from "../Layout/Goto Top/GoToTop";
import Input from "../UI/Input";
import { Country } from "country-state-city";
function Applicationform({ isScholarship = false }) {
  let personalData = {
    firstName: "",
    lastName: "",
    email: "",
    zipCode: "",
    phone: "",
    dob: "",
    studentType: "",
    country: "",
    addmissionFor: "",
    scholarship: isScholarship,
  };
  const navigate = useNavigate();
  let educationData = {
    school: "",
    yearOfCompletion: "",
    highestQualification: "",
    currentStatus: "",
  };

  let educationArea = {
    areaOfStudy: "",
    degreeLevel: "",
  };

  let documentationData = {
    passportOrBirthDoc: "",
    cvOrResume: "",
  };

  let declarationData = {
    applicationFullName: "",
    additionalInformation: "",
  };

  const [personalInfo, setPersonalInfo] = useState(personalData);
  const [educationInfo, seteducationInfo] = useState(educationData);
  const [educationBackground, setEducationBackground] = useState(educationArea);
  const [documentation, setDocumentation] = useState(documentationData);
  const [declaration, setDeclaration] = useState(declarationData);

  const [studentTypeList, setStudentTypeList] = useState([]);
  const [currentStatusList, setCurrentStatusList] = useState([]);
  const [studyArea, setStudyArea] = useState([]);
  const [degreeArea, setDegreeArea] = useState([]);

  const [admissionForList, setAdmissionForList] = useState([]);

  // State For Preview
  const [passportPreview, setPassportPreview] = useState("");
  const [cvPreview, setCvPreview] = useState("");
  const [fullNamePreview, setFullNamePreview] = useState("");

  useEffect(() => {
    fetchStudentType();
    fetchAdmissionFor()
  }, []);

  const fetchStudentType = async () => {
    const result = await requestData("user/viewStudentType", "GET");
    if (result && result.status) {
      setStudentTypeList(result.data);
    }
    fetchCurrentStatus();
  };

  const fetchCurrentStatus = async () => {
    const result = await requestData("user/viewCurrentStatus", "GET");
    if (result && result.status) {
      setCurrentStatusList(result.data);
    }
    fetchStydyArea();
  };

  const fetchStydyArea = async () => {
    const result = await requestData("user/viewAreaOfStudy", "GET");
    if (result && result.status) {
      setStudyArea(result.data);
    }
  };

  const fetchDegreeLevel = async (val) => {
    // console.log(val);

    const result = await requestData(`user/viewDegreeLevel/${val._id}`, "GET");
    // console.log("result", result);
    if (result && result.status) {
      setDegreeArea(result.data);
    }
  };

  const fetchAdmissionFor = async () => {
    let result = await requestData(`user/viewAdmissionDropDown`, "GET", {});

    if (result && result.status) {
      const allCategories = result.data.reduce((acc, item) => acc.concat(item.category), []);
      console.log('allCategories', allCategories)
      setAdmissionForList(allCategories); // set data
    } else {
      toast.error(result.error || result.message);
    }
  };


  const submitForm = async () => {
    let allObj = {}
    if (isScholarship) {
      allObj = {
        ...personalInfo,
        // ...educationInfo,
        // ...educationBackground,
        // ...documentation,
        ...declaration,
      };
    } else {
      allObj = {
        ...personalInfo,
      };
    }

    console.log("allobj", allObj);
    let piarr = Object.values(allObj);
    let validation = piarr.some((val) => val === "");
    if (validation) {
      toast.warn("all field are required !!!");
      return;
    }
    let data = {
      applicationDetails: personalInfo,
      educationRecord: educationInfo,
      educationDetails: educationBackground,
      documentation: documentation,
      declaration: declaration,
    };
    console.log(data);
    // return

    let res = await requestData("user/addApplicationForm", "POST", data);
    if (res && res.status) {
      toast.success("The VIP SUCCESS membership team will be in touch and will guide you through the next steps");
      setTimeout(() => {
        navigate("/admission");
      }, 1000);


      //   setPersonalInfo(personalData);
      //   seteducationInfo(educationData);
      //   setEducationBackground(educationArea);
      //   setDocumentation(documentationData);
      //   setDeclaration(declarationData);

    } else {
      toast.error(res.error || res.message);
    }
  };
  const onImageUpload = (e, type) => {
    // console.log("e", e);
    let file = e.target.files[0];

    imageUploadApi(file, type);
  };
  const imageUploadApi = async (file, type) => {
    let data = new FormData();
    data.append("image", file);

    let result = await fileUplode(
      "user/ApplicationFormdocumentUpload",
      "POST",
      data
    );
    // console.log("result", result);
    if (result && result.status) {
      if (type === "passport") {
        setDocumentation({ ...documentation, passportOrBirthDoc: result.url });
        setPassportPreview(result.url);
      } else if (type === "cv") {
        setDocumentation({ ...documentation, cvOrResume: result.url });
        setCvPreview(result.url);
      } else if (type === "name") {
        setDeclaration({ ...declaration, applicationFullName: result.url });
        setFullNamePreview(result.url);
      }
    }
  };
  //   console.log("first", declaration);
  return (
    <>
      <section className="contact_Us" id="contact_Us">
        <ToastContainer />
        <div className="contact_Us_SectionInner">
          <div className="form_COntactus" />
          <div className="form_TExtInnner">
            <h1 className="form_TExt">FORM</h1>
            <h4 style={{ textAlign: 'center', fontWeight: '600' }} className="section_Title">Join VIP SUCCESS GROWTH COMMUNITY - FREE TO JOIN</h4>
          </div>
          <div className="container  py-4" style={{ maxWidth: 1260, }}>
            <div className="application_Details mb-5">
              <h4 className="section_Title">Applicant Details</h4>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="First name"
                    placeholder="Enter First name"
                    onChange={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        firstName: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Last name"
                    placeholder="Enter Last name"
                    onChange={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        lastName: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Email address"
                    placeholder="Enter Email address"
                    onChange={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        email: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Zip code"
                    placeholder="Enter Zip code"
                    onChange={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        zipCode: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="number"
                    label="Phone number"
                    placeholder="Enter Phone number"
                    onChange={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        phone: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="date"
                    label="Date of birth"
                    placeholder="Enter Date of birth in dd/mm/yyyy format"
                    onChange={(e) => {
                      // data should be in dd-mm-yyyy format but default is yyyy-mm-dd
                      // console.log("e.target.value", e.target.value);
                      let date = e.target.value.split("-");
                      let dd = date[2];
                      let mm = date[1];
                      let yy = date[0];
                      setPersonalInfo({
                        ...personalInfo,
                        dob: `${dd}-${mm}-${yy}`,
                      });
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Country</label>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      onChange={(e) =>
                        setPersonalInfo({
                          ...personalInfo,
                          country: e.target.value,
                        })
                      }
                    >
                      {/* {console.log(
                        "Country.getAllCountries()",
                        Country.getAllCountries()
                      )} */}
                      <option value="">Please Select</option>
                      {Country.getAllCountries()?.map((item, index) => {
                        return (
                          <option key={index} value={item?.name}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Type</label>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      onChange={(e) =>
                        setPersonalInfo({
                          ...personalInfo,
                          studentType: e.target.value,
                        })
                      }
                    >
                      <option value="">Please Select</option>
                      {studentTypeList?.map((item, index) => {
                        return (
                          <option key={index} value={item?.studentType}>
                            {item?.studentType}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Admission for</label>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      onChange={(e) =>
                        setPersonalInfo({
                          ...personalInfo,
                          addmissionFor: e.target.value,
                        })
                      }
                    >
                      <option value="">Please Select</option>
                      {admissionForList?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="application_Details mb-5">
              <h4 className="section_Title">
                Education Records &amp; Achievements
              </h4>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Last Place Of Study"
                    placeholder="Enter here"
                    onChange={(e) =>
                      seteducationInfo({
                        ...educationInfo,
                        school: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Year of completion"
                    placeholder="Year of completion"
                    onChange={(e) =>
                      seteducationInfo({
                        ...educationInfo,
                        yearOfCompletion: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    label="Highest qualification"
                    placeholder="Highest qualification"
                    onChange={(e) =>
                      seteducationInfo({
                        ...educationInfo,
                        highestQualification: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Current status</label>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      onChange={(e) =>
                        seteducationInfo({
                          ...educationInfo,
                          currentStatus: e.target.value,
                        })
                      }
                    >
                      <option value="">Please Select</option>
                      {currentStatusList?.map((item, index) => {
                        return (
                          <option key={index} value={item?.currentStatus}>
                            {item?.currentStatus}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="application_Details mb-5">
              <h4 className="section_Title">Education Details</h4>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Select area of study</label>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      onChange={(e) => {
                        let data = JSON.parse(e.target.value);
                        setEducationBackground({
                          ...educationBackground,
                          areaOfStudy: data.areaOfStudy,
                        });
                        fetchDegreeLevel(data);
                      }}
                    >
                      <option value="">Please Select</option>
                      {studyArea?.map((item, index) => {
                        return (
                          <option key={index} value={JSON.stringify(item)}>
                            {item?.areaOfStudy}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Degree level</label>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      onChange={(e) =>
                        setEducationBackground({
                          ...educationBackground,
                          degreeLevel: e.target.value,
                        })
                      }
                    >
                       {console.log("sdfsdf", degreeArea)} 
                      <option value="">Please Select</option>
                      {degreeArea?.map((item, index) => {
                        return (
                          <option key={index} value={item?.degreeLevel}>
                            {item?.degreeLevel}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="application_Details mb-5">
              <h4 className="section_Title">Documentation</h4>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">
                      Upload passport or birth documentation
                    </label>
                    <Input
                      type="file"
                      label="No file Chosen"
                      onChange={(e) => {
                        onImageUpload(e, "passport");
                      }}
                    />
                    {passportPreview ? (
                      <div className="my-2">
                        <img
                          src={passportPreview}
                          height="100px"
                          widtth="100px"
                        />
                      </div>
                    ) : null}
                    <span className="upload_TExt">
                      Please upload a VERIFIED copy of your Passport or Birth
                      Certificate. VERIFIED means the original document has been
                      sighted &amp; the copy dated and signed by an authorised
                      person.
                    </span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">
                      Upload Curriculum Vitae (CV) or Resume
                    </label>
                    <Input
                      type="file"
                      label="No file Chosen"
                      onChange={(e) => {
                        onImageUpload(e, "cv");
                      }}
                    />
                    {cvPreview ? (
                      <div className="my-2">
                        <img src={cvPreview} height="100px" widtth="100px" />
                      </div>
                    ) : null}
                    <span className="upload_TExt">
                      Upload Curriculum Vitae (CV) or Resume
                    </span>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="application_Details mb-5">
              {
                isScholarship &&
                <h4 className="section_Title">Declaration</h4>
              }
              <div className="row">
                {
                  isScholarship &&
                  <div className="col-md-12">
                    <Input
                      type="textarea"
                      label="Additional information"
                      placeholder="Questions? Tell us why you deserve a scholarship"
                      onChange={(e) =>
                        setDeclaration({
                          ...declaration,
                          additionalInformation: e.target.value,
                        })
                      }
                    />
                  </div>
                }


                <div className="col-md-12">
                  <div className="form-group">
                    <input type="checkbox" />
                    <label htmlFor="">
                      By submitting this form, you agree to the VIP SUCCESS Academy
                      <Link to="/"> privacy notice. </Link>
                    </label>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <button
                      onClick={submitForm}
                      className="btn submitAPplicationBTn"
                    >
                      Submit Application
                      <i className="fa-solid fa-arrow-right-long ml-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GoToTop />
    </>
  );
}

export default Applicationform;
