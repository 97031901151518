import React from "react";

function Step3({ callMack,state }) {
  // console.log('state', state)
  return (
    <>
      <div className="amounts-fund">
        <div className="funds-details">
          <p className="Givingpage-amounts-fund-name">VIP-SUCCESS</p>
        </div>
        <div className="custom-amounts">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="$250"
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="funds-details">
        <p className="Givingpage-amounts-fund-name">SELECT GIFT TYPE</p>
      </div>
      <div className="amounts-GIFT_BTN">
        <button onClick={() => callMack("one time")}>ONE TIME</button>
        <select onChange={(e) => callMack(e.target.value)}>
          <option value="recurring">RECURRING</option>
          <option value="monthly">MONTHLY</option>
          <option value="quarterly">QUARTERLY</option>
          <option value="semi-annually">SEMI-ANNUALLY</option>
          <option value="annualy">ANNUALLY</option>
        </select>
      </div>
    </>
  );
}

export default Step3;
