import React from "react";
import { Link } from "react-router-dom";

const OurMoto = () => {
  return (
    <section id="our-moto">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-xl-4 col-6">
            <div className="our-main-aim" style={{ background: "rgb(205 212 251)" }}>
              <Link to="/about" className="btn btn-item">
                {/* Overview */}
                About
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 col-6">
            <div className="our-main-aim" style={{ background: "rgb(205 212 251)" }}>
              <Link to="/champion" className="btn btn-item">
                {/* Leadership */}
                Champion
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xl-4 col-6">
            <div className="our-main-aim" style={{ background: "rgb(205 212 251)" }}>
              <Link to="/history" className="btn btn-item">
                History
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-6 col-6">
            <div className="our-main-aim" style={{ background: "rgb(205 212 251)" }}>
              <Link to="/our-goal" className="btn btn-item">
                {/* Mission &amp; Values */}
                Our Goal
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-6 col-6">
            <div className="our-main-aim" style={{ background: "rgb(205 212 251)" }}>
              <Link to="/scholarship" className="btn btn-item">
                {/* Campus Information */}
                Scholarship
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="row"> */}
          {/* <div className="col-lg-6 col-md-6 col-xl-6 col-6">
            <div className="our-main-aim">
              <Link to="/our-goal" className="btn btn-item">
             
                Our Goal
              </Link>
            </div>
          </div> */}

        {/* </div> */}
      </div>
    </section>
  );
};

export default OurMoto;
