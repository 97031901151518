import React from "react";


function CampusSection({ campusData = null }) {
  console.log("CampusData", campusData);
  return (
    <>
      <section className="experience mt-5">
        <div className="about-camp">
          <div className="box">
            <h2 className="campus-titlequckj">{campusData?.heading}</h2>
          </div>
        </div>
        <div className="bac-img">
          {campusData?.video && (
            <video autoPlay loop id="video-background">
              <source src={campusData?.video} type="video/mp4" />
            </video>
          )}

          {/* <img src={about3} className="img-fluid" /> */}
          <div className="play-button">
            <a href="">
              <i className="fa-regular fa-circle-play" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default CampusSection;
