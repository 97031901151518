import React from 'react'
import { useNavigate } from 'react-router-dom'
import parse from "html-react-parser";

export const Scholarship = ({ scholarshipData = null }) => {
  const navigate = useNavigate()
  return (
    <>
      <section className="scholarship">
        {/* <div className="custom">
          <img
            src={scholarshipData?.image}
            className="img-fluid"
          />
        </div> */}
        <div className="container">
          <div className="content">
            <h1>leaders</h1>
            <div className="wrap-box">
              <h3 className="d-inline">
                {scholarshipData?.title}
                <br />
              </h3>
            </div>
            <div className=' schlrwrapboxtxtpp' style={{ marginTop: 25 }}>
              {parse(scholarshipData?.description || "")}
            </div>
            <button onClick={() => navigate("/apply-scholarship")}>
              Apply here
              <i className="fa-solid fa-arrow-right-long" />
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
