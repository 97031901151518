import React, { useState, useEffect } from 'react'
import about1 from "../../images/brydon-mccluskey-GvPceVqbxm4-unsplash.jpg"
import imageStudent from "../../images/student_Image.jpg";
import { requestData } from '../../Utils/HttpClient';
import Partnership from '../Home/Partnership';
import Colaboration from './Colaboration';

export default function Index() {

  const [partnerahipIntro, setPartnerahipIntro] = useState(null)
  const [partnershipData, setPartnershipData] = useState(null)
  const [colaborationData, setColaborationData] = useState(null)

  useEffect(() => {
    fetchPartnershipIntroData()
  }, [])


  const fetchPartnershipIntroData = async () => {
    const result = await requestData("user/viewPartnershipIntro", "GET");
    // console.log(result);
    if (result && result.status) {
      setPartnerahipIntro(result.data[0]);
    }
    fetchPartnershipData()
  };

  const fetchPartnershipData = async () => {
    const result = await requestData("user/viewPartnership", "GET");
    // console.log(result);
    if (result && result.status) {
      setPartnershipData(result.data[0]);
    }
    fetchColaborationData()
  };

  const fetchColaborationData = async () => {
    const result = await requestData("user/viewPartnerShipDegree", "GET");
    // console.log(result);
    if (result && result.status) {
      setColaborationData(result.data[0]);
    }
  };

  return (
    <>
      <section className="about-missoin" style={{ backgroundImage: `url(${partnerahipIntro?.bgImage})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="cnt">
                  <h1>{partnerahipIntro?.pageName}</h1>
                  <p>
                    {partnerahipIntro?.shortDesc}
                    {/* GWUA Is at the forefront of quality education And development
                    Where Humanity & prosperity for all Are anchored in everything we do. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="partnership-text">
        <div className="container">
          <div className='row'>
            <div className="scholarship-content">
              <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className="text" style={{ paddingTop: 20 }}>
                  <h2 className="scholarships-overview">{partnerahipIntro?.question}</h2>
                  <p style={{ marginBottom: 0 }}>{partnerahipIntro?.answer}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <Partnership partnershipData={partnershipData} />

      <Colaboration colaborationData={colaborationData} />

    </>
  )
}
