import React from "react";
const reg = /^[0-9]*\.?[0-9]*$/;

function Step2({ callMack, state }) {

  const handleChange = (e) => {
    let value = e.target.value.replace('$', '');
    if (reg.test(value)) {
      callMack(value);
    }
  };


  return (
    <>
      <h2 className="amount-price">ENTER GIFT AMOUNT</h2>
      <div className="amounts">
        <button onClick={() => callMack(1000)}>$1,000</button>
        <button onClick={() => callMack(500)}>$500</button>
      </div>
      <div className="amounts">
        <button onClick={() => callMack(250)}>$250</button>
        <button onClick={() => callMack(100)}>$100</button>
      </div>
      <div className="custom-amounts">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="$ OTHER AMOUNT"
            value={state ? "$" + state : ""}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}

export default Step2;
