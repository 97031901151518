import React from "react";

const RegDetails = ({ title, heading, option }) => {
  return (
    <section className="scholarship-delivery">
      <div className="container">
        <h2>{heading}</h2>
        {option?.map((item, ind) => {
          return (
            <div className="para-one">
              <h5 className="delivery-option">{title}</h5>
              <p>
                <strong>
                  <b>Online - Asynchronous:</b>
                </strong>
                 ​100% online delivery with no assigned day or time
                requirements, independent course completion
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default RegDetails;
