import React from "react";

const ChampionshipImage = ({ championshipImage = null }) => {
  // console.log("championshipImage", championshipImage);
  return (
    <>
      <section className="student_MultipleNumber" id="student_MultipleNumber">
        <div className="container custom_Container">
          <div className="row multipleRow">
            {championshipImage?.image.map((item, index) => {
              return (
                <div key={index} className="col-lg-4 multipleColumn">
                  <div className="student_LifeImge">
                    <img
                      src={item}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ChampionshipImage;
