import React from "react";
// import gal1 from "../../images/gallery_image1.jpg";
// import gal2 from "../../images/gallery_image2.jpg";
// import gal3 from "../../images/gallery_image3.jpg";
// import gal4 from "../../images/gallery_image4.jpg";
// import gal5 from "../../images/gallery_image5.jpg";
// import gal6 from "../../images/gallery_image6.jpg";
// import gal7 from "../../images/gallery_image7.jpg";

function EventGallery({ photoGallery = null }) {
  return (
    <>
      <section className="gallery_main_area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="gallery_main_top_box">
                <h5>Gallery</h5>
                <h2>{photoGallery?.title}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {
              photoGallery?.gallery.map((item,ind)=>{
                return (
                  <div className="col-md-4">
                  <div className="gallery_main_img_box">
                    {/* <div className="gallery_box">
                      <a href="#" data-fancybox="gallery">
                        <i className="fa fa-arrows" aria-hidden="true" />
                        <img src={photoGallery?.gallery[0]} className="img-fluid" />
                      </a>
                    </div> */}
                    <div className="gallery_box">
                      <a href={item} data-fancybox="gallery">
                        <i className="fa fa-arrows" aria-hidden="true" />
                        <img src={item} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                )
              })
            }
           
            {/* <div className="col-md-4">
              <div className="gallery_main_img_box">
                <div className="gallery_box">
                  <a href="#" data-fancybox="gallery">
                    <i className="fa fa-arrows" aria-hidden="true" />
                    <img src={photoGallery?.gallery[2]} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gallery_main_img_box">
                <div className="gallery_box">
                  <a href="#" data-fancybox="gallery">
                    <i className="fa fa-arrows" aria-hidden="true" />
                    <img src={photoGallery?.gallery[3]} className="img-fluid" />
                  </a>
                </div>
                <div className="gallery_box">
                  <a href="#" data-fancybox="gallery">
                    <i className="fa fa-arrows" aria-hidden="true" />
                    <img src={photoGallery?.gallery[4]} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="gallery_main_img_box">
                <div className="gallery_box">
                  <a href="#" data-fancybox="gallery">
                    <i className="fa fa-arrows" aria-hidden="true" />
                    <img src={photoGallery?.gallery[5]} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gallery_main_img_box">
                <div className="gallery_box">
                  <a href="#" data-fancybox="gallery">
                    <i className="fa fa-arrows" aria-hidden="true" />
                    <img src={photoGallery?.gallery[6]} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default EventGallery;
