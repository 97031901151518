import React, { useEffect, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import {
  getState,
  getCity,
  expirationMonth,
  expirationYear,
  viewMemorialType,
  viewSalutation,
} from "./OptionDataFrom";
const reg = /^[0-9]*$/;
function Step4({
  callMack,
  setCountre,
  setStatemain,
  setBiState,
  setgiftAnonymous,
  setmemorialType,
  setsalutationPI,
  setunitedStateIP,
  setstatePI,
  setexpirationYearIP,
  setexpirationMonthIP,
}) {
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [expMonths, setExpMonth] = useState([]);
  const [expYear, setExpYear] = useState([]);
  const [memorialType, setMemorialType] = useState([]);
  const [salutation, setSalutation] = useState([]);
  useEffect(() => {
    stateGet();
    cntGet();
    getExpMonth();
    getExpYear();
    getMemorialType();
    getSalutation();
  }, []);
  const stateGet = async () => {
    let state = await getState();
    if (Array.isArray(state)) {
      setStates(state);
    }
  };
  const cntGet = async () => {
    let c = await getCity();
    if (Array.isArray(c)) {
      setCountry(c);
    }
  };
  const getExpMonth = async () => {
    let c = await expirationMonth();
    if (Array.isArray(c)) {
      setExpMonth(c);
    }
  };
  const getExpYear = async () => {
    let c = await expirationYear();
    if (Array.isArray(c)) {
      setExpYear(c);
    }
  };
  const getMemorialType = async () => {
    let c = await viewMemorialType();
    if (Array.isArray(c)) {
      setMemorialType(c);
    }
  };
  const getSalutation = async () => {
    let c = await viewSalutation();
    if (Array.isArray(c)) {
      setSalutation(c);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    callMack(data);
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <label>First Name</label>
          <input
            placeholder="First Name"
            type="text"
            {...register("firstName", { required: true, maxLength: 10 })}
          />
        </Form.Field>
        {errors.firstName && <p>Please check the First Name</p>}
        <Form.Field>
          <label>Last Name</label>
          <input
            placeholder="Last Name"
            type="text"
            {...register("lastName", { required: true, maxLength: 10 })}
          />
        </Form.Field>
        {errors.lastName && <p>Please check the Last Name</p>}
        <Form.Field>
          <label>Email</label>
          <input
            placeholder="Email"
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
        </Form.Field>
        {errors.email && <p>Please check email</p>}
        <Form.Field>
          <label>Password</label>
          <input
            placeholder="Password"
            type="password"
            {...register("password", { required: true })}
          />
        </Form.Field>
        {errors.password && <p>Please check the Password</p>}
        <Form.Field>
          <label>Country</label>
          <select onChange={(e) => setCountre(e.target.value)}>
            <option value="">United States</option>
            {country.map((item, ind) => {
              return <option value={item.country}>{item.country}</option>;
            })}
          </select>
        </Form.Field>
        {errors.country && <p>Please select the country</p>}
        <Form.Field>
          <label>Street Address</label>
          <input
            placeholder="Street Address"
            type="text"
            {...register("streetAddress", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Street Address2</label>
          <input
            placeholder="Street Address2"
            type="text"
            {...register("streetAddress2", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>City</label>
          <input
            placeholder="City"
            type="text"
            {...register("city", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>State</label>
          {/* <select onChange={(e) => setStatemain(e.target.value)} name="" id="">
          <option value="">select state</option>
            {states.map((item, ind) => {
              return <option value={item.state}>{item.state}</option>;
            })}
          </select> */}
          <input
            placeholder="State"
            type="text"
            onChange={(e) => setStatemain(e.target.value)}
            {...register("state", { required: true})}
          />
        </Form.Field>
        <Form.Field>
          <label>Zip Code</label>
          <input
            placeholder="Zip Code"
            type="text"
            // {...register("zipcode", { required: true, pattern: /^[0-9]*$/ })}
            {...register("zipcode", { required: true})}
          />
        </Form.Field>
        {/* <p>
          Would you like to keep informed via email of activities & events at
          the University of Florida, University of Florida Foundation,
          University of Florida Alumni Association, Gator Boosters, Inc., and
          your specific college/unit?
        </p> */}
        {/* <Form.Field>
          <label>Email Opt-In</label>
          <select {...register("emailOptIn")} name="" id="">
            <option value="">Email Opt-In</option>
            <option value=""></option>
            <option value=""></option>
            <option value=""></option>
          </select>
        </Form.Field> */}
        <Form.Field>
          <label>Is this gift anonymous?</label>
          <select
            onChange={(e) => setgiftAnonymous(e.target.value)}
            name=""
            id=""
          >
            <option value="">Is this gift anonymous?</option>
            <option value="yes">Yes</option>
            <option value="no">NO</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Comments</label>
          <textarea
            {...register("comment")}
            type="text"
            placeholder=""
            name="comment"
          />
        </Form.Field>

        <div className="form-title">
          <div className="container">
            <div className="row">
              <h2>Memorial/Honorary Type</h2>
              <p>
                If this gift is in honor of or in memory of someone, please
                complete this section
              </p>
            </div>
          </div>
        </div>

        <Form.Field>
          <label>Memorial/Honorary Type</label>
          <select
            onChange={(e) => setmemorialType(e.target.value)}
            name=""
            id=""
          >
            <option value="">Memorial/Honorary Type</option>

            {memorialType.map((item, ind) => {
              return <option value={item.memorial}>{item.memorial}</option>;
            })}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Memorial Name</label>
          <input
            placeholder="Memorial Name"
            type="text"
            {...register("memorialName", { required: true })}
          />
        </Form.Field>

        <div className="form-title">
          <div className="container">
            <div className="row">
              <h2>Business Contact Information</h2>
              <p>
                If you would like to provide your business contact information,
                please complete this section
              </p>
            </div>
          </div>
        </div>

        <Form.Field>
          <label>Employer Name</label>
          <input
            placeholder="Employer Name"
            type="text"
            {...register("employerNameBI")}
          />
        </Form.Field>
        <Form.Field>
          <label>Job Title</label>
          <input
            placeholder="Job Title"
            type="text"
            {...register("jobTitleBI", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Country</label>
          <input
            placeholder="United States"
            type="text"
            {...register("countryBI", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Street Address</label>
          <input
            placeholder="Street Address"
            type="text"
            {...register("streetAddressBI", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Street Address2</label>
          <input
            placeholder="Street Address2"
            type="text"
            {...register("streetAddress2BI", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>City</label>
          <input
            placeholder="City"
            type="text"
            {...register("cityBI", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>State/Province</label>
          <input
            placeholder="State/Province"
            type="text"
            onChange={(e) => setBiState(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Zip/Postal Code</label>
          <input
            placeholder="Zip/Postal Code"
            type="number"
            {...register("zipcodeBI", { required: true })}
          />
        </Form.Field>

        <div className="form-title">
          <div className="container">
            <div className="row">
              <h2>Spouse/Partner Information</h2>
              <p>
                If you would like to provide your spouse’s/partner’s contact
                information, please complete this section
              </p>
            </div>
          </div>
        </div>

        <Form.Field>
          <label>Salutation</label>
          <select
            onChange={(e) => setsalutationPI(e.target.value)}
            name=""
            id=""
          >
            <option value="">Salutation</option>
            {salutation.map((item, ind) => {
              return <option value={item.salutation}>{item.salutation}</option>;
            })}
          </select>
        </Form.Field>
        <Form.Field>
          <label>First Name</label>
          <input
            placeholder="First Name"
            type="text"
            {...register("firstNamePI", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Middle</label>
          <input
            placeholder="Middle"
            type="text"
            {...register("middleNamePI", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Last Name</label>
          <input
            placeholder="Last Name"
            type="text"
            {...register("lastNamePI", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <input
            placeholder="Email"
            type="email"
            {...register("emailPI", { required: true })}
          />
        </Form.Field>
        {/* <p>
          Would your spouse like to keep informed via email of activities &
          events at the University of Florida, University of Florida Foundation,
          University of Florida Alumni Association, Gator Boosters, Inc., and
          their specific college/unit?
        </p> */}

        {/* <Form.Field>
          <label>Spouse Opt-In</label>
          <select name="" id="">
            <option value="">Spouse Opt-In</option>
            <option value=""></option>
            <option value=""></option>
            <option value=""></option>
          </select>
        </Form.Field> */}

        <div className="form-title">
          <div className="container">
            <div className="row">
              <h2>Payment Information</h2>
            </div>
          </div>
        </div>

        <Form.Field>
          <label>First Name</label>
          <input
            placeholder="First Name"
            type="text"
            {...register("firstNameIP", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Last Name</label>
          <input
            placeholder="Last Name"
            type="text"
            {...register("lastNameIP", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Cardholder Email</label>
          <input
            placeholder="Cardholder Email"
            type="email"
            {...register("cardHolderEmailIP", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>United States</label>
          <select
            onChange={(e) => setunitedStateIP(e.target.value)}
            name=""
            id=""
          >
            <option value="">United States</option>

            {states.map((item, ind) => {
              return <option value={item.state}>{item.state}</option>;
            })}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Street Address</label>
          <input
            placeholder="Street Address"
            type="text"
            {...register("streetAddressIP")}
          />
        </Form.Field>
        <Form.Field>
          <label>Street Address2</label>
          <input
            placeholder="Street Address2"
            type="text"
            {...register("streetAddress2IP")}
          />
        </Form.Field>
        <Form.Field>
          <label>City</label>
          <input placeholder="City" type="text" {...register("cityPI",{ required: true })} />
        </Form.Field>
        <Form.Field>
          <label>State</label>
          <select
            onChange={(e) => {
              setstatePI(e.target.value);
            }}
            name=""
            id=""
          >
            <option value="">State</option>
            {states.map((item, ind) => {
              return <option value={item.state}>{item.state}</option>;
            })}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Zip Code</label>
          <input
            placeholder="Zip Code"
            type="number"
            {...register("zipcodePI", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Credit Card Number</label>
          <input
            placeholder="Credit Card Number"
            type="text"
            {...register("creditCardNumberIP", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Verification Code</label>
          <input
            placeholder="Verification Code"
            type="text"
            {...register("verificationCodeIP", { required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>Expiration Month</label>
          <select
            onChange={(e) => setexpirationMonthIP(e.target.value)}
            name=""
            id="Expiration Months"
          >
            <option value="">Expiration Month</option>
            {expMonths.map((item, ind) => {
              return (
                <option value={item.expirationMonth}>
                  {item.expirationMonth}
                </option>
              );
            })}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Expiration Year</label>
          <select
            onChange={(e) => setexpirationYearIP(e.target.value)}
            name=""
            id=""
          >
            <option value="">Expiration Year</option>
            {expYear.map((item, ind) => {
              return (
                <option value={item.expirationYear}>
                  {item.expirationYear}
                </option>
              );
            })}
          </select>
        </Form.Field>

        <Button type="submit">Submit</Button>
      </Form>
    </>
  );
}

export default Step4;
