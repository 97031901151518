import React, { useState, useEffect } from "react";

import Form from "../../component/Form/Form";
import a4 from "../../images/history_Image.jpg";
import GoToTop from "../../component/Layout/Goto Top/GoToTop";
import { Link } from "react-router-dom";
import { requestData } from "../../Utils/HttpClient";
import LongDesc from "./LongDesc";
import HistorySection from "./HistotySection";
import CampusSection from "./CampusSection";
import Designation from "./Designation";
import Experience from "./Experience";
import AboutImage from "./AboutImage";
import StudentInfo from "./StudentInfo";
import Mission from "./Mission";
import News from "../Home/News";
import Intro from "../../component/Home/Intro/Intro";

export default function Index() {
  const [aboutData, setAboutData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [campusData, setCampusData] = useState(null);
  const [newsData, setNewsData] = useState(null);
  const [experienceData, setExperienceData] = useState(null);
  const [missionData, setMissionData] = useState(null);

  useEffect(() => {
    fetchAboutHistoryData();
  }, []);

  const fetchAboutHistoryData = async () => {
    const result = await requestData("user/viewAboutIntro", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setAboutData(result.data[0]);
      // console.log("AboutHis", result.data[0]);
    }
    fetchHistoryData();
  };

  const fetchHistoryData = async () => {
    const result = await requestData("user/viewHistorydetails", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setHistoryData(result.data[0]);
    }
    fetchCampusData();
  };

  const fetchCampusData = async () => {
    const result = await requestData("user/viewCampus", "GET");
    // console.log("Bannerdata", result);
    if (result && result.status) {
      setCampusData(result.data[0]);
    }
    fetchNewsData();
  };

  const fetchNewsData = async () => {
    const result = await requestData("user/viewNewsAndEvent", "GET");
    console.log("result",result);
    if (result && result.status) {
      setNewsData(result.data);
    }
    fetchExperienceData();
  };

  const fetchExperienceData = async () => {
    const result = await requestData("user/viewOrganisationInfo", "GET");
    // console.log(result);
    if (result && result.status) {
      setExperienceData(result.data[0]);
    }
    fetchMissionData();
  };

  const fetchMissionData = async () => {
    const result = await requestData("user/viewMissionAndValues", "GET");
    // console.log(result);
    if (result && result.status) {
      setMissionData(result.data[0]);
    }
  };

  return (
    <>
      <Intro
        bgImage={aboutData?.bgImage}
        pageName={aboutData?.pageName}
        description={aboutData?.shortDesc}
      />
      <LongDesc aboutData={aboutData} />

      <Experience experienceData={experienceData} />

      <AboutImage experienceData={experienceData} />

      <StudentInfo experienceData={experienceData} />

      {/* <section
        className="financial_aid_area about-finance"
        style={{ marginLeft: 40, marginRight: 40 }}
      >
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <div className="left_cnt">
                  <div className="image_area">
                    <img src={a4} className="img-fluid" alt="image" />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 m-auto">
                <div className="right_cnt">
                  <div className="head">
                    <h2>History</h2>
                  </div>
                  <div className="text">
                    <p>
                      our, a group of visionaries sought a new and evolved model
                      of education, one providing the intellectual and creative
                      freedom to tackle the most pressing issues of the day.
                    </p>
                  </div>
                  <div className="howtoapply_btn">
                    <Link to="/history" className="btn_custom">
                      Explore Our History
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <HistorySection historyData={historyData} marginTop={10} />

      <Mission missionData={missionData} />

      {/* <CampusSection campusData={campusData} /> */}

      <Designation campusData={campusData} />

      {/* <section className="emma_matthews_area">
        <div className="container-fluid">
          <div className="main-wrap row">
            <div className="col-lg-5 col-md-12 col-12">
              <div
                className="left_cnt"
                style={{ backgroundImage: `url('${a6}')` }}
              >
                <div style={{ position: "relative" }} className="president">
                  <img src={a8} className="img-fluid" alt="img" />
                  <div
                    className="img_area"
                    style={{ backgroundImage: `url('${a7}')` }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="right_cnt">
                <div className="head">
                  <h2>Dr. Ram P. Ramcharran</h2>
                </div>
                <div className="line">
                  <h6>Founder</h6>
                </div>
                <div className="text">
                  <p>
                    I am passionate about education and business, and I have
                    been able to work in areas that make me happy and where I
                    feel I can make a difference. As an entrepreneur, consultant
                    and author who has served with the US Army I offer you my
                    support in being the best version of you. We Look forward to
                    supporting you with your Growth and Success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <News newsData={newsData} />

      <Form />
      <GoToTop />
    </>
  );
}
